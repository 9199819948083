import React, { useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Descriptions } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import CreatedAt from "../Columns/CreatedAt";
import TabRoute from './TabRoute'
import queryString from 'query-string'
import Pagination from "../../utils/Pagination";
import Filters from "../Minigame/Filters";
import { userRollCallService } from "../../services/rollCall";
import Moment from "react-moment";

function History(props) {
  const [rowData, setRowData] = useState(null)
  const { t } = useTranslation()

  const [firstReload, setFirstReload] = useState(true)

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }
      
      if(_.get(params,'query.mobile','').length > 11){
        _.set(params,'query.mobile','');
      }

      if(_.get(query,'mobile','').length != 10){
        _.set(query,'mobile','');
      }

      const paramsResult = firstReload ? {
        ..._.get(params, 'query', {}),
        ...query,
      } : {
        ...query,
        ..._.get(params, 'query', {}),
      }

      setFirstReload(false)

      const response = await userRollCallService({...paramsResult})
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {
      console.log({error});
    }
  };

  return (
    <TabRoute {...props}>
      <div className="content__header w-100">
        <Filters {...props} onFiltersChange={(payload) => {
          onGridReady({
            query: payload
          })
        }} />
      </div>
      <div className="content__table ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
            editable:true
          }}
          animateRows={true}
          rowHeight={75}
          headerHeight={36}
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={_.get(rowData, 'datas', [])}
        >
          <AgGridColumn headerName={t("fullName")} flex={1} suppressSizeToFit={true} field="user.fullName" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("mobile")} width={200} suppressSizeToFit={true} field="user.mobile" wrapText={true} valueSetter={({param})=>{
            return false;
          }}/>
          <AgGridColumn headerName={t("bonus box")} flex={1} width={350} wrapText={true} cellRendererFramework={({data}) => {
            return (
              <Descriptions className="component__user">
                <Descriptions.Item span={3}>
                  Tên phần thưởng: {data?.rollCall?.name}
                </Descriptions.Item>
                <Descriptions.Item span={3}>
                  Giá trị: <NumberFormat style={{marginLeft: '5px'}} value={data?.rollCall?.balance} thousandSeparator={true} suffix=" đ" displayType={'text'} />
                </Descriptions.Item>
              </Descriptions>)
        }} field="box" wrapText={true} editable={false}/>
          <AgGridColumn editable={false} headerName={t("Ngày nhận")} width={200} suppressSizeToFit={true} 
            cellRendererFramework={({data}) => {
              if(!data?.dayReceive) return null
              return <Moment parse="YYYY-MM-DD" format="DD/MM/YYYY">{data?.dayReceive}</Moment>
            }} field="dayReceive" wrapText={true} 
          />
          <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} width={220} suppressSizeToFit={true} field="createdAt" wrapText={true} editable={false}/>
        </AgGridReact>
      </div>
      <Pagination {...props} hasNext={rowData?.hasNext} onPageChange={(payload) => {
        onGridReady({
          query: payload
        })
      }} />
    </TabRoute>
  );
}

export default History;
