import axiosClient from "./axiosClient"

const prefix = '/admin/lucky-money'

export const listLuckyMoneyService = async(params = {}) => {
  return await axiosClient.get(`${prefix}`, {params})
}

export const createLuckeyMoneyService = async(payload) => {
  return await axiosClient.post(`${prefix}`, payload)
}

export const detailLuckeyMoneyService = async(id, params = {}) => {
  return await axiosClient.get(`${prefix}/${id}`, {params})
}

export const updateLuckeyMoneyService = async(id, params = {}) => {
  return await axiosClient.put(`${prefix}/${id}`, params)
}