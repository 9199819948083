import React, { useState, useContext } from 'react';

import { Modal, Form, Input, InputNumber, message } from 'antd';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import { UserContext } from '../User';
import { updateAddressService } from '../../../services/user';

function ChangeAddress(props) {
  const { visible, setVisible, onUpdate, address } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const context = useContext(UserContext)

  const handleSubmitForm = async () => {
    try {
      const key = "update_address"
      const values = await form.validateFields()
      
      const userId = _.get(context,"selectedRows[0]._id",null)
      message.loading({ content: t("Loading..."), key });
      setVisible(false)
      try {
        const response = await updateAddressService(userId, values)
        onUpdate(_.get(response, 'results', ""))
        form.resetFields()
        message.success({ content: t("Update address success"), key });
      } catch (error) {
        console.log("error", error);
        setVisible(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={t("Update Address")}
      okText={t("Edit")}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          ..._.pick(address, 'fullName', 'phone', 'area', 'location')
        }}
      >
        <Form.Item
          name="fullName" label={t("fullName")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            placeholder={t("Enter fullName")}
          />
        </Form.Item>
        <Form.Item
          name="phone" label={t("phone")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            placeholder={t("Enter phone")}
          />
        </Form.Item>
        <Form.Item
          name="area" label={t("Khu vực")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            placeholder={t("Enter area")}
            rules={[{ required: true, message: t("Please do not leave this field blank") }]}
          />
        </Form.Item>
        <Form.Item
          name="location" label={t("Địa chỉ cụ thể")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            placeholder={t("Enter location")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangeAddress;