import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

import {authentication} from './reducers/auth';

const loggerMiddleware = createLogger();
const rootReducer = combineReducers({
    auth: authentication
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware));

export default store;
