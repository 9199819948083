import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Modal, Tooltip, Alert, Input, Row, Col, Tag, Button, message } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import { CloseCircleOutlined, InfoCircleOutlined, ExclamationCircleOutlined, IssuesCloseOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import CreatedAt from "../../components/Columns/CreatedAt";
import Status from "../Columns/Status";
import { useParams } from "react-router-dom";
import { bypassFrozen, cancelOrderJackpot, getListOrderUser } from "../../services/package";
import ConfigOrder from "./modal/ConfigOrder";
import { getUserService } from "../../services/user";
import StatusOrder from "../Columns/StatusOrder";

export const OrderContext = React.createContext();

function Order(props) {
  const [rowData, setRowData] = useState(null);
  const [userInfo , setUserInfo] = useState(null)
  const [showPopupConfigOrder, setShowPopupConfigOrder] = useState(false)
  const [selectedRows, setSelectedRows] = useState({})
  const [gridApi, setGridApi] = useState(null)
  const { t } = useTranslation()
  const { userId } = useParams()

  const onGridReady = async (params) => {
    setGridApi(params.api)
    try {
      await fetchOrder()
    } catch (error) {
      console.log('error');
    }
  }

  const showPromiseConfirmCancel = ({ data, rowIndex }) => {
    Modal.confirm({
        title: t('Bạn có muốn hủy cấu hình đơn hàng này'),
        icon: <ExclamationCircleOutlined />,
        content: t('Thao tác không thể hoàn tác'),
        async onOk() {
            try {
                await cancelOrderJackpot(data._id)
                await fetchOrder()
                message.success(t('Hủy thàng công'));
            } catch (error) {
                console.log("error", error);
            }
        }
    });
  }

  const showPromiseConfirmBypass = ({ data, rowIndex }) => {
    Modal.confirm({
        title: t('Bạn có muốn hủy đóng băng đơn hàng này'),
        icon: <ExclamationCircleOutlined />,
        content: t('Thao tác không thể hoàn tác'),
        async onOk() {
            try {
                await bypassFrozen(data._id)
                await fetchOrder()
                message.success(t('Hủy thàng công'));
            } catch (error) {
                console.log("error", error);
            }
        }
    });
  }

  const fetchOrder = async () => {
    const response = await getListOrderUser(userId)
    const results = _.get(response, 'results', []);
    setRowData(results)
  }

  const onFirstDataRendered = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      if (column.colId !== 'description') {
        allColumnIds.push(column.colId);
      }
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  const updateShowPopupConfigOrder = (value) => {
    setShowPopupConfigOrder(value)
  }

  const refreshOrder = async () =>{
    await fetchOrder()
  }

  const onSelectionChanged = () => {
    setSelectedRows(gridApi.getSelectedRows())
  }

  const getUserInfo = async () => {
    const res = await getUserService(userId,{type: "not-level"})
    const results = _.get(res, 'results', []);
    setUserInfo(results)
  }
  useEffect(()=>{
    getUserInfo()
  },[])

  return (
    <OrderContext.Provider
      value={{
        showPopupConfigOrder: showPopupConfigOrder,
        selectedRows: selectedRows,
        updateShowPopupConfigOrder: updateShowPopupConfigOrder,
        userId: userId,
        refreshOrder: refreshOrder
      }}
    >
      <OrderContext.Consumer>
        {() => (
          <>
            <Alert
              message={`Người dùng: ${userInfo?.fullName || "Loading"} - Username: ${userInfo?.username || "Loading"}`}
              description={`Đang thuộc giang hàng: ${userInfo?.package?.name || "Chưa có"}`}
              type="info"
              showIcon
              style={{marginBottom: 10}}
            />
            <ConfigOrder />
            <div className="content__table ag-theme-alpine">
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  lockPosition: true,
                  wrapText: true,
                  resizable: true,
                  editable:true,
                }}
                rowHeight={130}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={()=>{onSelectionChanged()}}
                animateRows={true}
                headerHeight="36"
                rowSelection="single"
                floatingFiltersHeight="10"
                onGridReady={onGridReady}
                rowData={rowData}
              >
              <AgGridColumn headerName={t("Đơn hàng số")} suppressSizeToFit={true} maxWidth={80} field="orderNum" editable={true} wrapText={true} valueSetter={({param})=>{
                              return false;
                          }}/>
                <AgGridColumn headerName={t("Mã đơn")} suppressSizeToFit={true} maxWidth={120} field="orderCode" editable={true} wrapText={true} valueSetter={({param})=>{
                              return false;
                          }}/>
                <AgGridColumn headerName={t("Sản phẩm")} suppressSizeToFit={true} maxWidth={150} field="product.name" wrapText={true} editable={false} cellRendererFramework={({ value }) => {
                  return value.length > 40 ? value.slice(0,40)+ "..." : value
                }} valueSetter={({param})=>{
                              return false;
                          }}/>
                <AgGridColumn headerName={t("Giang hàng")} suppressSizeToFit={true} maxWidth={150} field="package.name" editable={true} wrapText={true} valueSetter={({param})=>{
                              return false;
                          }}/>
                <AgGridColumn headerName={t("Số tiền DH")} flex={1} maxWidth={130} suppressSizeToFit={true} field="minToup" wrapText={true} cellRendererFramework={({ value, data }) => {
                  return <NumberFormat value={data.jackpot ? value : data.total} thousandSeparator={true} suffix=" USD" displayType={'text'} />
                }} valueSetter={({param})=>{
                              return false;
                          }}/>
                <AgGridColumn headerName={t("Hoa hồng")} suppressSizeToFit={true} width={100} cellRendererFramework={({ value }) => {
                  return <NumberFormat value={_.round(value,2)} thousandSeparator={true} suffix=" USD" displayType={'text'} />
                }} field="commission" wrapText={true} valueSetter={({param})=>{
                  return false;
              }}/>
                <AgGridColumn headerName={t("Loại đơn hàng")} suppressSizeToFit={true} maxWidth={100} cellRendererFramework={({ value }) => {
                  let cellValue = "Thường"
                  let color = "#108ee9"
                  if(value){
                      cellValue = "Nổ hũ"
                      color = "#f5ce42"
                  }
                  return (
                      <div>
                        <Tag color={color}>{t(cellValue)}</Tag>
                      </div>
                  );
                }} field="jackpot" wrapText={true} valueSetter={({param})=>{
                  return false;
              }}/>
                <AgGridColumn headerName={t("status")} suppressSizeToFit={true} maxWidth={150} cellRendererFramework={StatusOrder} field="status" wrapText={true} editable={false}/>
                
                <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} width={150} suppressSizeToFit={true} field="createdAt" wrapText={true}  editable={false}/>
                <AgGridColumn cellStyle={{
                  textAlign: 'center'
                }} headerName={t("action")}
                  width={150}
                  cellRendererFramework={(dataCell) => {
                    return (
                      <div>
                        { dataCell.data.status !== "frozen" && <Tooltip placement="topLeft" title={t('Cài đặt đơn hàng')} arrowPointAtCenter>
                          <Button disabled={dataCell.data.status !== "draf" } style={{ marginRight: '.5rem' }} onClick={() =>setShowPopupConfigOrder(true)}  icon={<InfoCircleOutlined />}></Button>
                        </Tooltip> }
                        { dataCell.data.status == "frozen" && <Tooltip placement="topLeft" title={t('Hủy đóng băng')} arrowPointAtCenter>
                          <Button disabled={dataCell.data.bypass }  style={{ marginRight: '.5rem' }} onClick={() => showPromiseConfirmBypass(dataCell)} icon={<IssuesCloseOutlined />} danger></Button>
                        </Tooltip>}
                        <Tooltip placement="topLeft" title={t('Hủy cài đặt')} arrowPointAtCenter>
                          <Button  disabled={dataCell.data.status !== "draf" || !dataCell.data.jackpot } onClick={() => showPromiseConfirmCancel(dataCell)} icon={<CloseCircleOutlined />} danger></Button>
                        </Tooltip>
                      </div>
                    )
                  }} pinned="right" editable={false}
                />
              </AgGridReact>
            </div>
          </>
        )}
      </OrderContext.Consumer>
    </OrderContext.Provider>
  );
}

export default Order;
