import React from "react";
import Counter from "./home/Counter";
import User from "./home/User";
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
const role = Cookies.get("role");
const Home = (props) => {
  const history = useHistory();
  if (!Cookies.get("token")) return null;
  return (
    <div style={{ overflow: 'hidden', height: 'calc(100vh - 30px)', margin: -15 }}>
      <Counter role={role} />
      <User history={history} {...props} role={role} />
    </div>
  );
};

export default Home;
