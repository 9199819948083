import { Types } from '../constants/actionTypes';
import Cookies from 'js-cookie';

let token = Cookies.get("token");
const initialState = token ? { loggedIn: true, token } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case Types.LOGIN_REQUEST:
            return {
                loggingIn: true,
                token: action.token
            };

        case Types.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                token: action.token
            };

        case Types.LOGIN_FAILURE:
            return {};

        case Types.USER_LOGOUT:
            return {};

        default:
            return state
    }
}