import {Types} from '../constants/actionTypes';
import {authService} from "../../services/authService";

export const userActions = {
    login,
    logout
};

function login(phone, password) {
    return dispatch => {
        dispatch(request({phone}));
        authService.login(phone, password)
            .then(
                token => {
                    dispatch(success(token));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(token) {
        return {type: Types.LOGIN_REQUEST, token}
    }

    function success(token) {
        return {type: Types.LOGIN_SUCCESS, token}
    }

    function failure(error) {
        return {type: Types.LOGIN_FAILURE, error}
    }
}

function logout() {
    authService.logout();
    return {type: Types.USER_LOGOUT};
}