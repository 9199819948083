import React, { useState } from 'react';

import { Modal, Form, Input, InputNumber, message, Upload, Button, Radio } from 'antd';
import { UploadOutlined } from "@ant-design/icons"
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import { updateProductService, uploadImage } from '../../services/product';

function CollectionEditForm(props) {
  const { visible, setVisible, onUpdate, dataSelect } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [filePath , setFilePath] = useState("")

  const handleSubmitForm = async () => {
    try {
      const key = "create_transaction"

      const values = await form.validateFields()
      if(filePath){
        Object.assign(values,{image: filePath})
      }
      message.loading({ content: t("Loading..."), key });
      setVisible(false)
      try {
        await updateProductService(dataSelect._id, values)
        onUpdate()
        form.resetFields()
        setFilePath("")
        message.success({ content: t("Update Product success"), key });
      } catch (error) {
        console.log("error", error);
        setVisible(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  const uploadProps = {
    beforeUpload: async (file) => {
      const isPNG =['image/png','image/jpg','image/jpeg'].includes(file.type)
      if (!isPNG) {
        message.error(`${file.name} is not a png/jpg file`);
      }
      const formData = new FormData();
      formData.append('file', file);
      const res = await uploadImage(formData)
      setFilePath(res.results.path)
      return false;
    },
    onRemove: (file) => {
     setFilePath("")
    },
  };

  return (
    <Modal
      visible={visible}
      title={t("Update Product")}
      okText={t("Edit")}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ ...dataSelect }}
      >
        <Form.Item
          name="name" label={t("name")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            placeholder={t("Enter name")}
          />
        </Form.Item>
        <Form.Item
          name="price" label={t("Giá sản phẩm")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
            { type: "number", message: t("The input is a number") }
          ]}
        >
          <InputNumber
            placeholder={t('Enter amount')}
            className="w-100"
            formatter={value => `USD ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\USD\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item name="active" label={'Trạng thái'}>
          <Radio.Group>
              <Radio value={true}>Hiện</Radio>
              <Radio value={false}>Ẩn</Radio>
          </Radio.Group>
        </Form.Item>
        <div>
          <p>Ảnh sản phẩm</p>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        </div>
      </Form>
    </Modal>
  );
}

export default CollectionEditForm;