import React, { useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Button, Tooltip, Badge, Modal } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import CreatedAt from "../Columns/CreatedAt";
import TabRoute from './TabRoute'
import queryString from 'query-string'
import { EditOutlined } from "@ant-design/icons";
import EditMiniGame from "../Form/EditMiniGame";
import { listRollCallService } from "../../services/rollCall";
import EditRollCall from "../Form/EditRollCall";

function RollCall(props) {
  const [rowData, setRowData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [dataSelect, setDataSelect] = useState({})
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }

      const response = await listRollCallService({
        ...query,
        ..._.get(params, 'query', {})
      })
      const results = _.get(response, 'results.datas', []);
      setRowData(results)
    } catch (error) {}
  };

  return (
    <TabRoute {...props}>
      <div className="content__header w-100"></div>
      <div className="content__table ag-theme-alpine">
        <EditRollCall
            key={`${_.get(dataSelect, '_id', 1)}_${_.get(dataSelect, 'updatedAt', 1)}`}
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            onEdit={() => onGridReady()}
            dataSelect={dataSelect}
          />
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
            editable:true
          }}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={rowData}
        >
          <AgGridColumn headerName={t("name")} flex={1} suppressSizeToFit={true} field="name" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("Số tiền")} flex={1}  suppressSizeToFit={true} 
            cellRendererFramework={({value}) => {
              return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
            }} field="balance" editable={false} wrapText={true} 
          />
          <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} editable={false} suppressSizeToFit={true} field="createdAt" wrapText={true} editable={false}/>
          <AgGridColumn editable={false} headerName={t("action")}
            width={110}
            cellStyle={{
              textAlign: 'center'
            }}
            cellRendererFramework={({ data }) => {
              return <Tooltip placement="topLeft" title={t('Edit')} arrowPointAtCenter>
                  <Button style={{ backgroundColor: "#faad14", borderColor: "#faad14" }} type="primary" onClick={async () => {
                    await setDataSelect(data)
                    await setVisibleEdit(true)
                  }} icon={<EditOutlined />}></Button>
                </Tooltip>
            }} pinned="right"
          />
        </AgGridReact>
      </div>
    </TabRoute>
  );
}

export default RollCall;
