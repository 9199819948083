import { AgGridColumn, AgGridReact } from "ag-grid-react";
import _ from "lodash";
import React, { useState } from 'react';
import queryString from 'query-string'
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import CreatedAt from "../Columns/CreatedAt";
import TabRoute from "./TabRoute";
import { listSavingService } from "../../services/saving";
import CollectionEditFormSaving from "./CollectionEditFormSaving";

function Saving(props) {
  const [rowData, setRowData] = useState(null);
  const [visible, setVisible] = useState(false)
  const [dataSelect, setDataSelect] = useState({})
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }
      const response = await listSavingService({
        ...query,
        ..._.get(params, 'query', {})
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {
      console.log('error');
    }
  };

  return (
    <TabRoute {...props}>
      <CollectionEditFormSaving
        key={_.get(dataSelect, '_id', 1)}
        visible={visible}
        dataSelect={dataSelect}
        setVisible={setVisible}
        onUpdate={() => {
          setDataSelect({})
          onGridReady()
        }}
      />
      <div className="content__table ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
          }}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={rowData}
        >
          <AgGridColumn headerName={t("name saving")} flex={1} suppressSizeToFit={true} field="name" wrapText={true} />
          {/* <AgGridColumn headerName={t("price saving")} suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value?.price} thousandSeparator={true} suffix=" đ" displayType={'text'} />
          }} field="package" wrapText={true} /> */}
          <AgGridColumn headerName={t("duration saving")} suppressSizeToFit={true} field="duration" wrapText={true} />
          <AgGridColumn headerName={t("Giá gói")} field="balance" suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
          }} wrapText={true} />
          <AgGridColumn headerName={t("Lãi suất ngày")} field="interestDay" suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
          }} wrapText={true} />
          <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} suppressSizeToFit={true} field="createdAt" wrapText={true} />
          <AgGridColumn cellStyle={{
            textAlign: 'center'
          }} headerName={t("action")}
            width={110}
            cellRendererFramework={({ data }) => {
              return <Tooltip placement="topLeft" title={t('Edit')} arrowPointAtCenter>
                <Button style={{ backgroundColor: "#faad14", borderColor: "#faad14" }} type="primary" onClick={async () => {
                  await setDataSelect(data)
                  await setVisible(true)
                }} icon={<EditOutlined />}></Button>
              </Tooltip>
            }} pinned="right"
          />
        </AgGridReact>
      </div>
    </TabRoute >
  );
}

export default Saving;