import axiosClient from "./axiosClient"
const prefix = `${process.env.REACT_APP_API_ENDPOINT}/admin/withdraw`

export const listWithdawnService = async (query = {}) => {
  return await axiosClient.get(prefix, {
    params: query
  })
}

export const verifyWithdrawService = async (transactionID, status = "approved") => {
  return await axiosClient.put(`${prefix}/verify-withdraw/${transactionID}`, {
    status
  })
}