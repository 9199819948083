import { AgGridColumn, AgGridReact } from "ag-grid-react";
import _ from "lodash";
import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { CheckCircleOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Tag, Tooltip, Image } from "antd";
import CollectionEditForm from "./CollectionEditForm";
import TabRoute from "./TabRoute";
import { listProductService } from "../../services/product";
import Pagination from "../../utils/Pagination";
import CollectionCreateForm from "./CollectionCreateForm";

function Product(props) {
  const [rowData, setRowData] = useState(null);
  const [pkgs, setPkgs] = useState([])
  const [visible, setVisible] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [pkgSelect, setPkgSelect] = useState(null);
  const [dataSelect, setDataSelect] = useState({})
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)
      if (!query) {
        query = {}
      }
      const response = await listProductService({
        ...query
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {
      console.log('error');
    }
  };


  const onFirstDataRendered = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      if (column.colId !== 'description') {
        allColumnIds.push(column.colId);
      }
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  return (
    <TabRoute {...props}>
      <CollectionEditForm
        key={_.get(dataSelect, '_id', 1)}
        visible={visible}
        dataSelect={dataSelect}
        setVisible={setVisible}
        onUpdate={() => {
          setDataSelect({})
          onGridReady({})
        }}
      />
      <div className="content__action">
          <Tooltip placement="bottomRight" title={t('Create')}>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setVisibleCreate(true);
              }} type="primary">
              {t('Create')}
            </Button>
          </Tooltip>
          <CollectionCreateForm
            visible={visibleCreate}
            setVisible={setVisibleCreate}
            pkgs={pkgs}
            onUpdate={() => {
              onGridReady({})
            }}
          />
        </div>
      <div className="content__table ag-theme-alpine">
        
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
          }}
          onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={_.get(rowData, 'products', [])}
        >
          <AgGridColumn headerName={t("name")} maxWidth={400} suppressSizeToFit={true} field="name" wrapText={true} />
          <AgGridColumn headerName={t("price")} suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value} thousandSeparator={true} suffix=" USD" displayType={'text'} />
          }} field="price" wrapText={true} />
          <AgGridColumn headerName={t("ảnh")} suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <Image src={`https://cdn.dknyshop.com/${value}`} width={100} height={100}></Image>
          }} field="image" wrapText={true} />
          <AgGridColumn cellStyle={{
            textAlign: 'center'
          }} headerName={t("action")}
            width={110}
            cellRendererFramework={({ data }) => {
              return <Tooltip placement="topLeft" title={t('Edit')} arrowPointAtCenter>
                <Button style={{ backgroundColor: "#faad14", borderColor: "#faad14" }} type="primary" onClick={async () => {
                  await setDataSelect(data)
                  await setVisible(true)
                }} icon={<EditOutlined />}></Button>
              </Tooltip>
            }} pinned="right"
          />
        </AgGridReact>
      </div>
      <Pagination {...props} hasNext={rowData?.hasNext} onPageChange={(payload) => {
        onGridReady({
          query: payload
        })
      }} />
    </TabRoute >
  );
}

export default Product;