import React, { useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Button, Tooltip, Badge, Modal } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import CreatedAt from "../Columns/CreatedAt";
import TabRoute from './TabRoute'
import { destroyBonusBoxService, listBonusBoxService } from "../../services/minigame";
import queryString from 'query-string'
import { CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
// import CreateMiniGame from "../Form/CreateMiniGame";
import EditMiniGame from "../Form/EditMiniGame";

function Minigame(props) {
  const [rowData, setRowData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [dataSelect, setDataSelect] = useState({})
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }

      const response = await listBonusBoxService({
        ...query,
        ..._.get(params, 'query', {})
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {}
  };

  const onFirstDataRendered = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      if (['name', 'color', 'value'].indexOf(column.colId) == -1) {
        allColumnIds.push(column.colId);
      }
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  // const handleDestroy = async(data) => {
  //   Modal.confirm({
  //     title: 'Bạn có chắc chắn muốn xóa?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'Vui lòng xác nhận để có thể xóa',
  //     okText: 'Đồng ý',
  //     okType: 'danger',
  //     cancelText: 'Hủy',
  //     async onOk() {
  //       await destroyBonusBoxService(data._id)
  //       onGridReady()
  //     }
  //   });
  // }

  return (
    <TabRoute {...props}>
      <div className="content__header w-100">
        {/* <div className="content__action" style={{width: '100%'}}>
          <Tooltip placement="bottomRight" title={t('Create')}>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setVisible(true);
              }} 
              type="primary"
            >
              {t('Create')}
            </Button>
          </Tooltip>
          <CreateMiniGame
            visible={visible}
            setVisible={setVisible}
            onCreate={() => onGridReady()}
          />
        </div> */}
      </div>
      <div className="content__table ag-theme-alpine">
        <EditMiniGame
            key={`${_.get(dataSelect, '_id', 1)}_${_.get(dataSelect, 'updatedAt', 1)}`}
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            onEdit={() => onGridReady()}
            dataSelect={dataSelect}
          />
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
            editable:true
          }}
          onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={rowData}
        >
          <AgGridColumn headerName={t("name")} flex={1} suppressSizeToFit={true} field="name" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("color")} width={200} suppressSizeToFit={true} cellRendererFramework={({value}) => {
            return <Badge color={value} text={value} />
          }}  field="color" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("value")} width={200} suppressSizeToFit={true} 
            cellRendererFramework={({value}) => {
              return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
            }} field="value" editable={false} wrapText={true} 
          />
          <AgGridColumn headerName={t("percent")} width={200} suppressSizeToFit={true}
            cellRendererFramework={({value}) => {
              return `${value} %`
            }}
            field="percent"
            wrapText={true}
            editable={false}
          />  
          <AgGridColumn headerName={t("order")} width={100} suppressSizeToFit={true} editable={false} cellRendererFramework={({value}) => {
            return <Badge 
              count={value} 
              style={{ backgroundColor: '#52c41a' }}
            />
          }}  field="order" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} editable={false} suppressSizeToFit={true} field="createdAt" wrapText={true} editable={false}/>
          <AgGridColumn editable={false} headerName={t("action")}
            width={110}
            cellStyle={{
              textAlign: 'center'
            }}
            cellRendererFramework={({ data }) => {
              return <Tooltip placement="topLeft" title={t('Edit')} arrowPointAtCenter>
                  <Button style={{ backgroundColor: "#faad14", borderColor: "#faad14" }} type="primary" onClick={async () => {
                    await setDataSelect(data)
                    await setVisibleEdit(true)
                  }} icon={<EditOutlined />}></Button>
                </Tooltip>
            }} pinned="right"
          />
        </AgGridReact>
      </div>
    </TabRoute>
  );
}

export default Minigame;
