import axiosClient from "./axiosClient"

const prefix = '/admin/package-saving'

export const listSavingService = async (query = {}) => {
  return await axiosClient.get(prefix, {
    params: query
  })
}

export const updateSavingService = async (id, payload) => {
  return await axiosClient.put(`${prefix}/${id}`, payload)
}