import React, { Component, useState, useContext } from 'react';
import { Button, Modal, message, Tooltip, Switch, InputNumber, Form } from 'antd';
import { CreditCardOutlined,ArrowUpOutlined, EnvironmentOutlined, DisconnectOutlined , ExclamationCircleOutlined, LockOutlined, CloseCircleOutlined, CheckCircleOutlined, EyeOutlined, DollarCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import lodash from "lodash";
import axiosClient from "../../../services/axiosClient";
import { listPaymentService } from '../../../services/payment';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'
import { UserContext } from '../User';
import { getAddressService } from '../../../services/user';
const role = Cookies.get("role");
function NavigateOrder(props) {
    const history = useHistory()
    return (
        <Tooltip placement="topLeft" title={"Xem đơn hàng"} arrowPointAtCenter>
            <Button onClick={() => history.push(`/orders/${props.orderId}`)} icon={<ShoppingCartOutlined type="ghost" />} style={{ marginRight: '.5rem' }}></Button>      
        </Tooltip>
    );
}
  

class GenderRenderer extends Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
        this.changActive = this.changActive.bind(this);
        this.state = {
            processFetchPayment: false,
            processFetchAddress: false
        }
    }
    delete() {
        let data = this.props.data;
        Modal.confirm({
            title: 'Xóa mật khẩu cấp 2?',
            icon: <ExclamationCircleOutlined />,
            content: `Bạn xác nhận xóa mật khẩu cấp 2 của: ${lodash.get(data, 'fullName', '')}`,
            onOk() {
                axiosClient.delete(`${process.env.REACT_APP_API_ENDPOINT}/admin/user/password-level2/${lodash.get(data, '_id', '')}`).then((resp) => {
                    message.success(resp.results.message);
                }).catch((err) => {
                    message.error(err.results.message);
                }).finally(() => {

                });
            }
        });
    }
    editPayment = async () => {
        this.setState({ processFetchPayment: true })
        const userId = this.props?.data?._id
        if (!userId) {
            message.error({ content: "Không thể xác định User ID" })
        }
        const response = await listPaymentService({
            user: userId
        })

        const payment = lodash.get(response, 'results.payments[0]', '')
        if (!payment) {
            message.error({ content: "Người dùng chưa cập nhật thông tin ngân hàng" })
        }
        this.setState({ processFetchPayment: false })
        this.context.editPayment(payment)
    }
    editAddress = async () => {
        this.setState({ processFetchAddress: true })
        const userId = this.props?.data?._id
        if (!userId) {
            message.error({ content: "Không thể xác định User ID" })
        }
        const response = await getAddressService(userId)

        const address = lodash.get(response, 'results', '')
        if (!address) {
            message.error({ content: "Người dùng chưa cập nhật địa chỉ" })
        }
        this.setState({ processFetchAddress: false })
        this.context.editAddress(address)
    }
    changActive = async () => {
        let data = this.props.data;
        let self = this;
        Modal.confirm({
            title: data.active ? 'Khóa tài khoản?' : 'Mở tài khoản?',
            icon: <ExclamationCircleOutlined />,
            content: `Bạn xác nhận ${data.active ? 'khóa' : 'mở'} tài khoản: ${lodash.get(data, 'fullName', '')}`,
            onOk() {
                axiosClient.put(`${process.env.REACT_APP_API_ENDPOINT}/admin/user/${lodash.get(data, '_id', '')}`, {
                    'active': data.active ? false : true
                }).then((resp) => {
                    message.success(resp.results.message);
                    self.context.fetchApi();
                }).catch((err) => {
                    message.error(lodash.get(err, 'results.message', 'Error'));
                }).finally(() => {

                });
            }
        });
    }

    onSetAgent = () => {
        let self = this;
        let data = this.props.data;
        Modal.confirm({
            title: data.isAgent ? 'Xác nhận bỏ quyền truy cập vào trang quản lý' : 'Xác nhận truy cập vào trang quản lý',
            icon: <ExclamationCircleOutlined />,
            content: `Bạn xác nhận ${data.isAgent ? 'bỏ quyền' : ''} truy cập vào trang quản lý: ${lodash.get(data, 'fullName', '')}`,
            onOk() {
                console.log(data)
                axiosClient.put(`${process.env.REACT_APP_API_ENDPOINT}/admin/user/${lodash.get(data, '_id', '')}`, {
                    'isAgent': data.isAgent ? false : true
                }).then((resp) => {
                    message.success(resp.results.message);
                    self.context.fetchApi();
                }).catch((err) => {
                    message.error(lodash.get(err, 'results.message', 'Error'));
                }).finally(() => {

                });
            }
        });
    }

    render() {
        return (
            <div>
                {role === 'admin' && <>
                    { this.props.data.editUsername && <Tooltip placement="topLeft" title="Đổi username" arrowPointAtCenter>
                        <Button style={{ marginRight: '.5rem' }} onClick={() => this.context.updateShowPopupChangeUsername(true)} icon={<DisconnectOutlined type="ghost" />}></Button>
                    </Tooltip> }
                    <Tooltip placement="topLeft" title="Đổi mật khẩu" arrowPointAtCenter>
                        <Button style={{ marginRight: '.5rem' }} onClick={() => this.context.updateShowPopupChangePassword(true)} icon={<LockOutlined type="ghost" />}></Button>
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Đổi mật khẩu cấp 2" arrowPointAtCenter>
                        <Button style={{ marginRight: '.5rem' }} onClick={() => this.context.updateShowPopupChangePassword2(true)} icon={<LockOutlined type="ghost" />}></Button>
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Cập nhật thông tin ngân hàng" arrowPointAtCenter>
                        <Button loading={this.state.processFetchPayment} onClick={this.editPayment} icon={<CreditCardOutlined type="ghost" />} style={{ marginRight: '.5rem' }}></Button>
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Cập nhật địa chỉ nhận hàng" arrowPointAtCenter>
                        <Button loading={this.state.processFetchAddress} onClick={this.editAddress} icon={<EnvironmentOutlined type="ghost" />} style={{ marginRight: '.5rem' }}></Button>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={this.props.data.active ? 'Khóa tài khoản' : 'Mở tài khoản'} arrowPointAtCenter>
                        <Button onClick={this.changActive} style={{ marginRight: '.5rem' }} icon={this.props.data.active ? <CloseCircleOutlined type="ghost" /> : <CheckCircleOutlined type="ghost" />}></Button>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={"Nâng gian hàng"} arrowPointAtCenter>
                        <Button onClick={() => this.context.updateShowPopupUpgradePackage(true)} icon={<ArrowUpOutlined type="ghost" />} style={{ marginRight: '.5rem' }}></Button>
                    </Tooltip>
                    <NavigateOrder orderId={this.props.data._id} />
                    
                </>
                }
            </div>
        );
    }
}

const ViewDetail = ({ data }) => {

    const history = useHistory();
    const onRedirect = (url) => {
        history.push({
            pathname: url,
            state: { id: data?._id }
        })
    }
    return (
        <Tooltip placement="topLeft" title="Xem chi tiết" arrowPointAtCenter>
            <Button style={{ marginRight: '.5rem' }} onClick={() => onRedirect('user-detail')} icon={<EyeOutlined type="ghost" />}>
            </Button>
        </Tooltip>
    )
}

const PointVip = (props) => {
    const { data } = props;
    const context = useContext(UserContext);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChangePointVip = () => {
        setShow(!show);
        form.resetFields()
    }
    const handleOk = async (e) => {
        const values = await form.validateFields();
        axiosClient.put(`${process.env.REACT_APP_API_ENDPOINT}/admin/user/exchange-vip/${lodash.get(data, '_id', '')}`, { ...values })
            .then((resp) => {
                message.success(resp.results.message);
                onChangePointVip();
                context.fetchApi();
            }).catch((err) => {
                // message.error(lodash.get(err, 'results.message', 'Error'));
            }).finally(() => {
                form.resetFields()
            });
    }

    return (
        <>
           <Modal
                title="Đổi điểm Vip"
                visible={show}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onChangePointVip}
                okText={'Đổi điểm'}
                width={700}
                cancelText={t("Cancel")}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        initialValue={data.pointVip}
                        name="pointVip"
                        label="Điểm Vip:"
                        rules={[{ required: true, message: 'Vui lòng nhập điểm Vip' }]}
                    >
                        <InputNumber placeholder="Điểm Vip" className="w-100" />
                    </Form.Item>

                    <Form.Item
                        initialValue="0"
                        name="balanceExchange"
                        label="Tiền quy đổi:"
                        rules={[{ required: true, message: 'Vui lòng nhập số tiền quy đổi' }]}
                    >
                        <InputNumber placeholder="Tiền quy đổi" className="w-100" />
                    </Form.Item>
                </Form>
            </Modal>
            <Tooltip placement="topLeft" title="Đổi điểm" arrowPointAtCenter>
                <Button style={{ marginRight: '.5rem' }} onClick={onChangePointVip} icon={<DollarCircleOutlined type="ghost" />}>
                </Button>
            </Tooltip>
        </>
    )
}

export default GenderRenderer;
