import React, { useEffect } from 'react';

import { Modal, Form, InputNumber, message, Row, Col, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next'
import _ from 'loadsh'
import { createLuckeyMoneyService, updateLuckeyMoneyService } from '../../services/lucky';
import moment from 'moment';

function CreateLucky(props) {
  const { visible, setVisible, onCreate, item } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        active: item.active,
        balance: item.balance,
        issue: item.issue,
        valid: [item.validFrom ? moment(item.validFrom) : null, item.validTo ? moment(item.validTo) : null],
      });
    }
  }, [item])

  const handleSubmitForm = async () => {
    try {
      const key = "create_lucky"

      const values = await form.validateFields()
      message.loading({ content: t("Loading..."), key });
      if (values.valid) {
        values.validFrom = moment(values.valid[0]);
        values.validTo = moment(values.valid[1]);
        delete values.valid;
      }
      try {
        const response = item ? await updateLuckeyMoneyService(item._id, values) : await createLuckeyMoneyService(values);

        onCreate(_.get(response, 'results', ""))
        form.resetFields()
        message.success({ content: t(`${item ? 'Cập nhật' : 'Tạo'} lì xì thành công`), key });
        setVisible(false)
      } catch (error) {
        console.log("error", error);
        setVisible(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={t(`${item ? 'Sửa lì xì' : 'Tạo lì xì'}`)}
      okText={t(`${item ? 'Sửa' : 'Create'}`)}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Form.Item name="active" label={t("Trạng thái")}>
              <Select>
                <Select.Option value={false}>Tạm hoãn</Select.Option>
                <Select.Option value={true}>Phát hành</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <Form.Item
              name="issue" label={t("Số bao lì xì")}
              rules={[
                { required: true, message: t("Please do not leave this field blank") },
              ]}
            >
              <InputNumber
                placeholder={t("Nhập số bao lì xì")}
                className="w-100"
                min="0"
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="balance" label={t("Tiền")}
              rules={[
                { required: true, message: t("Please do not leave this field blank") },
                { type: "number", message: t("The input is a number") }
              ]}
            >
              <InputNumber
                placeholder={t('Nhập tiền')}
                className="w-100"
                formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\VND\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Form.Item name="valid" label={t("Hiệu lực")}>
              <DatePicker.RangePicker className="w-100" format="DD/MM/YYYY"
                ranges={{
                  'Hôm nay': [moment(), moment()],
                  'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                }}
                placeholder={[t('Hiệu lực từ'), t('Hiệu lực đến')]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateLucky;