import React from "react";
import { Modal, Button, Input, Space,message } from 'antd';
import  lodash from "lodash";
import { updateUserService } from "../../../services/user";

class ChangeUsername extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            username:"",
            loading:false,
        }
        this.handleOk = this.handleOk.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleOk = () => {
        if(this.state.username && this.context.selectedRows.length){
            this.saveData(this.context.selectedRows[0]._id,this.state.username);
        }
    };

    saveData = (id,username)=>{
        this.setState({
            loading:true
        })
        updateUserService(id,{username}).then((resp)=>{
            message.success(resp.results.message);
        }).catch((err)=>{
            message.error(err.results.message);
        }).finally(()=>{
            this.setState({
                loading:false
            })
            this.context.updateShowPopupChangeUsername(false)
        });
    }

    handleCancel = () => {
        this.context.updateShowPopupChangeUsername(false)
    };

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
    render() {
        return (
            <div>
                <Modal
                    title="Đổi username"
                    visible={this.context.showPopupChangeUsername}
                    onOk={this.handleOk}
                    confirmLoading={this.state.loading}
                    onCancel={this.handleCancel}
                >
                        <h4>{lodash.get(this.context,"selectedRows[0].username","")}</h4>
                        <label>Username:</label>
                        <Input placeholder="input username" value={this.state.username} name="username"  onChange={this.handleChange}/>
                </Modal>
            </div>
        )
    }
}

export default ChangeUsername;