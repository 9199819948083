import React from 'react';

import { Modal, Form, Input, InputNumber, message, Alert } from 'antd';
import { useTranslation } from 'react-i18next'
import { updateBonusBoxService } from '../../services/minigame';
import _ from 'loadsh'

function EditMiniGame(props) {
  const { visible, setVisible, onEdit, dataSelect } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmitForm = async () => {
    try {
      const key = "create_bonus_box"

      const values = await form.validateFields()
      message.loading({ content: t("Loading..."), key });
      setVisible(false)
      try {
        const response = await updateBonusBoxService(_.get(dataSelect, '_id'), values)

        onEdit(_.get(response, 'results', ""))
        form.resetFields()
        message.success({ content: t("Edit bonus success"), key });
      } catch (error) {
        console.log("error", error);
        setVisible(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={t("Edit bonus box")}
      okText={t("Edit")}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{...dataSelect}}
      >
        <Form.Item
          name="name" label={t("name")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input className="w-100" placeholder={t('Enter name')} />
        </Form.Item>
        <Form.Item
          name="value" label={t("amount")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
            { type: "number", message: t("The input is a number") }
          ]}
        >
          <InputNumber
            placeholder={t('Enter amount')}
            className="w-100"
            formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\VND\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name="color" label={t("color")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input className="w-100" placeholder={t('Enter color')} />
        </Form.Item>
        {/* <Alert style={{marginBottom: "1rem"}} message="Lấy mã màu ở https://www.google.com/search?q=color+picker" type="warning" /> */}
        <Form.Item
          name="percent" label={t("percent")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber 
            placeholder={t("Enter percent")}
            className="w-100"
            min="0"
          />
        </Form.Item>
        <Form.Item
          name="order" label={t("order")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber 
            placeholder={t("Enter order")}
            className="w-100"
            min="0"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditMiniGame;