import React, { useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Button, Tooltip, Badge, Modal, Tag, Descriptions } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import CreatedAt from "../Columns/CreatedAt";
import TabRoute from './TabRoute'
import { destroyBonusBoxService, disableUserIngameService, listBonusBoxService, listHistoryService, listUserInGameService } from "../../services/minigame";
import queryString from 'query-string'
import { CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined, PauseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
// import CreateMiniGame from "../Form/CreateMiniGame";
// import EditMiniGame from "../Form/EditMiniGame";
import CreateUserInGame from "../Form/CreateUserInGame";
import Pagination from "../../utils/Pagination";
import BonusBox from "./BonusBox";
import Filters from "./Filters";

function History(props) {
  const [rowData, setRowData] = useState(null)
  // const [visible, setVisible] = useState(false)
  // const [visibleEdit, setVisibleEdit] = useState(false)
  // const [dataSelect, setDataSelect] = useState({})
  const { t } = useTranslation()

  const [firstReload, setFirstReload] = useState(true)

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }
      
      if(_.get(params,'query.mobile','').length != 10){
        _.set(params,'query.mobile','');
      }

      if(_.get(query,'mobile','').length != 10){
        _.set(query,'mobile','');
      }

      const paramsResult = firstReload ? {
        ..._.get(params, 'query', {}),
        ...query,
      } : {
        ...query,
        ..._.get(params, 'query', {}),
      }

      setFirstReload(false)

      const response = await listHistoryService({...paramsResult})
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {
      console.log({error});
    }
  };

  // const onFirstDataRendered = (params) => {
  //   var allColumnIds = [];
  //   params.columnApi.getAllColumns().forEach(function (column) {
  //     if (['name', 'color', 'value'].indexOf(column.colId) == -1) {
  //       allColumnIds.push(column.colId);
  //     }
  //   });
  //   params.columnApi.autoSizeColumns(allColumnIds, false);
  // };

  // const handleDisable = async(data) => {
  //   Modal.confirm({
  //     title: 'Bạn có chắc chắn muốn hủy?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'Vui lòng xác nhận để có thể hủy',
  //     okText: 'Đồng ý',
  //     okType: 'danger',
  //     cancelText: 'Hủy',
  //     async onOk() {
  //       await disableUserIngameService(data._id)
  //       onGridReady()
  //     }
  //   });
  // }

  return (
    <TabRoute {...props}>
      <div className="content__header w-100">
        <Filters {...props} onFiltersChange={(payload) => {
          onGridReady({
            query: payload
          })
        }} />
        {/* <div className="content__action" style={{width: '100%'}}>
          <Tooltip placement="bottomRight" title={t('Create')}>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setVisible(true);
              }} 
              type="primary"
            >
              {t('Create')}
            </Button>
          </Tooltip>
          <CreateUserInGame
            visible={visible}
            setVisible={setVisible}
            onCreate={() => onGridReady()}
          />
        </div> */}
      </div>
      <div className="content__table ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
            editable:true
          }}
          // onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          rowHeight={75}
          headerHeight={36}
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={_.get(rowData, 'datas', [])}
        >
          <AgGridColumn headerName={t("fullName")} flex={1} suppressSizeToFit={true} field="user.fullName" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("mobile")} width={200} suppressSizeToFit={true} field="user.mobile" wrapText={true} valueSetter={({param})=>{
            return false;
          }}/>
          <AgGridColumn headerName={t("bonus box")} flex={1} width={350} wrapText={true} cellRendererFramework={({data}) => {
            return (
              <Descriptions className="component__user">
                <Descriptions.Item span={3}>
                  Tên phần thưởng: {data?.box?.name}
                </Descriptions.Item>
                <Descriptions.Item span={3}>
                  Giá trị: <NumberFormat style={{marginLeft: '5px'}} value={data?.box?.value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
                </Descriptions.Item>
              </Descriptions>)
        }} field="box" wrapText={true} editable={false}/>
          {/* <AgGridColumn headerName={t("box name")} flex={1} suppressSizeToFit={true} field="box.name" wrapText={true} editable={false}/> */}
          <AgGridColumn editable={false} headerName={t("cost ticket")} width={200} suppressSizeToFit={true} 
            cellRendererFramework={({data}) => {
              return <NumberFormat value={data?.costTicket} thousandSeparator={true} suffix=" đ" displayType={'text'} />
            }} field="costTicket" wrapText={true} 
          />
          {/* <AgGridColumn headerName={t("percent")} width={200} suppressSizeToFit={true}
            cellRendererFramework={({value}) => {
              return `${value} %`
            }}
            field="box.percent"
            wrapText={true}
          /> */}
          {/* <AgGridColumn headerName={t("status")} cellRendererFramework={({value}) => {
            return !value ? <Tag color="#108ee9">Chuẩn bị</Tag> : <Tag color="#f50">Đã hủy</Tag>
          }} width={130} suppressSizeToFit={true} field="turned" wrapText={true} editable={false}/> */}
          <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} width={220} suppressSizeToFit={true} field="createdAt" wrapText={true} editable={false}/>
          {/* <AgGridColumn headerName={t("action")}
            cellStyle={{
              textAlign: 'center'
            }}
            width={110}
            cellRendererFramework={({ data }) => {
              return <Tooltip placement="topLeft" title={t('Disable')} arrowPointAtCenter>
                <Button disabled={data?.turned} style={{ backgroundColor: "#faad14", borderColor: "#faad14" }} type="primary" onClick={async () => handleDisable(data)} icon={<PauseCircleOutlined />}></Button>
              </Tooltip>
            }} pinned="right"
          /> */}
        </AgGridReact>
      </div>
      <Pagination {...props} hasNext={rowData?.hasNext} onPageChange={(payload) => {
        onGridReady({
          query: payload
        })
      }} />
    </TabRoute>
  );
}

export default History;
