import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Modal, Tooltip, DatePicker, Input, Row, Col, Tag, Button, message } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, CheckCircleTwoTone, ExclamationCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import CreatedAt from "../../components/Columns/CreatedAt";
import TabRoute from "../Transaction/TabRoute";
import Pagination from "../../utils/Pagination";
import queryString from 'query-string'
import Status from "../Columns/Status";
import { listWithdawnService, verifyWithdrawService } from "../../services/withdraw";
import Payment from "../Columns/Payment";
import Filters from "../../utils/Filters";

function Withdrawn(props) {
  const [rowData, setRowData] = useState(null);
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }
      const response = await listWithdawnService({
        ...query,
        ..._.get(params, 'query', {})
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {
      console.log('error');
    }
  };

  const showPromiseConfirm = ({ data, rowIndex }, status) => {
    Modal.confirm({
      cancelText: t("Cancel"),
      okText: t('Ok'),
      title: t('Do you want to approval these items?'),
      icon: <ExclamationCircleOutlined />,
      content: t('Please confirm before approval this item'),
      async onOk() {
        try {
          const response = await verifyWithdrawService(data?._id, status)
          onGridReady()
          message.success(t('Approval request transaction success'));
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  }

  const onFirstDataRendered = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      if (column.colId !== 'description') {
        allColumnIds.push(column.colId);
      }
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  return (
    <TabRoute {...props}>
      <div className="content__header w-100">
        <Filters {...props} onFiltersChange={(payload) => {
          onGridReady({
            query: payload
          })
        }} />
      </div>
      <div className="content__table ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
            editable:true,
          }}
          rowHeight={130}
          onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={_.get(rowData, 'datas', [])}
        >
          <AgGridColumn headerName={t("user")} suppressSizeToFit={true} field="user.mobile" wrapText={true} valueSetter={({param})=>{
                        return false;
                    }}/>
          <AgGridColumn headerName={t("payment")} suppressSizeToFit={true} field="payment" cellRendererFramework={Payment} wrapText={true} editable={false} valueSetter={({param})=>{
                        return false;
                    }}/>
          <AgGridColumn headerName={t("Số tài khoản")} suppressSizeToFit={true} minWidth={150} field="payment.numberBank" editable={true} wrapText={true} valueSetter={({param})=>{
                        return false;
                    }}/>
          <AgGridColumn headerName={t("amount")} suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
          }} field="amount" wrapText={true} valueSetter={({param})=>{
            return false;
        }}/>
          <AgGridColumn headerName={t("amountTransfer")} suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
          }} field="amountTransfer" wrapText={true} valueSetter={({param})=>{
            return false;
        }}/>
          <AgGridColumn headerName={t("status")} suppressSizeToFit={true} minWidth={150} cellRendererFramework={Status} field="status" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("description")} flex={1} minWidth={350} suppressSizeToFit={true} field="description" wrapText={true} valueSetter={({param})=>{
                        return false;
                    }}/>
          <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} suppressSizeToFit={true} field="createdAt" wrapText={true}  editable={false}/>
          <AgGridColumn cellStyle={{
            textAlign: 'center'
          }} headerName={t("action")}
            width={110}
            cellRendererFramework={(dataCell) => {
              return (
                <div>
                  <Tooltip placement="topLeft" title={t('Approval')} arrowPointAtCenter>
                    <Button style={{ marginRight: '.5rem' }} disabled={_.get(dataCell, 'data.status', 'pending') != 'pending'} type="primary" onClick={() => showPromiseConfirm(dataCell, 'approved')} icon={<CheckCircleTwoTone />}></Button>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={t('Reject')} arrowPointAtCenter>
                    <Button disabled={_.get(dataCell, 'data.status', 'pending') != 'pending'} type="primary" onClick={() => showPromiseConfirm(dataCell, 'reject')} icon={<CloseCircleOutlined />} danger></Button>
                  </Tooltip>
                </div>
              )
            }} pinned="right" editable={false}
          />
        </AgGridReact>
      </div>
      <Pagination {...props} hasNext={rowData?.hasNext} onPageChange={(payload) => {
        onGridReady({
          query: payload
        })
      }} />
    </TabRoute>
  );
}

export default Withdrawn;
