import React, { Component } from 'react';
import { Button } from 'antd';


export default class GenderRenderer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Button className="mr-3" type="primary" onClick={()=>this.context.updateShowPopup(true,this.props.data)}>Sửa</Button>
            </div>
        );
    }
}
