import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Status(props) {
  const { t } = useTranslation();
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value

  if (cellValue == 'pending') {
    return <Tag icon={<ExclamationCircleOutlined />} color="warning">
      {t(cellValue)}
    </Tag>
  } else if (cellValue == 'reject') {
    return <Tag icon={<ExclamationCircleOutlined />} color="red">
      {t(cellValue)}
    </Tag>
  }
  return <Tag icon={<CheckCircleOutlined />} color="success">
    {t(cellValue)}
  </Tag>
}

export default Status;