import React from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Spin, Row, Col, Divider, message, Button } from 'antd';
import axiosClient from "../../services/axiosClient";
import { Input } from 'antd';
import moment from "moment";
import Action from "./Action";
import lodash from "lodash";
import Pagination from "../../utils/Pagination";
import queryString from 'query-string';
import Popup from "./Popup";
import {
    BrowserRouter as Router,
    Switch,
    useLocation
} from "react-router-dom";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const { Search } = Input;
//Khởi tạo môt
const UserContext = React.createContext();
Action.contextType = UserContext;
Popup.contextType = UserContext;

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: 'Tên',
                    field: 'title',
                },
                {
                    headerName: 'Mô tả',
                    field: 'description',
                    suppressSizeToFit: true,
                },
                {
                    headerName: 'Ngày tạo',
                    field: 'createdAt',
                    width: 150,
                    suppressSizeToFit: true,
                    valueFormatter: (params) => {
                        return moment(params.data.createAt).format("DD/MM/YYYY");
                    }
                },
                {
                    headerName: 'Hành động',
                    field: 'action',
                    width: 50,
                    cellRenderer: "Action",
                    pinned: 'right'
                },
            ],
            defaultColDef: { resizable: true },
            rowData: [],
            loading: false,
            gridApi: {},
            frameworkComponents: {
                Action: Action
            },
            showPopup: false,
            selectedRows: {},
            hasNext: false,
        }
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this)
        this.onGridReady = this.onGridReady.bind(this)
        this.create = this.create.bind(this)

    }
    componentDidUpdate() {
    }
    create() {
        this.setState({
            selectedRows: {},
            showPopup: true
        })
    }
    reFetchApi(value) {
        if (value) {
            this.fetchApi();
        }
    }
    fetchApi(params) {
        axiosClient.get(`${process.env.REACT_APP_API_ENDPOINT}/admin/notification`, {
            params: lodash.get(params, 'query', {})
        }).then((resp) => {
            this.setState({
                rowData: resp.results.datas,
                hasNext: resp.results.hasNext
            })
        }).catch((resp) => {

        }).finally(() => {
            this.setState({
                loading: false
            });
        })
    }
    componentDidMount() {

    }
    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };
    async onGridReady(params) {
        this.setState({
            gridApi: params.api
        })
        await this.fetchApi(params);
        this.state.gridApi.sizeColumnsToFit();
    }
    updateShowPopup = async (value, data = {}) => {
        await this.setState({
            selectedRows: data
        });
        await this.setState({
            showPopup: value,
        });
    };
    onSelectionChanged = () => {
        this.setState({
            selectedRows: this.state.gridApi.getSelectedRows()
        })
    };
    render() {
        return (
            <UserContext.Provider
                value={{
                    showPopup: this.state.showPopup,
                    selectedRows: this.state.selectedRows,
                    updateShowPopup: this.updateShowPopup.bind(this),
                    reFetchApi: this.reFetchApi.bind(this)
                }}
            >
                <UserContext.Consumer>
                    {() => (
                        <>
                            <Popup
                                key={lodash.get(this.state.selectedRows, '_id', 1)}
                                showPopup={this.state.showPopup}
                                selectedRows={this.state.selectedRows}
                                updateShowPopup={this.updateShowPopup}
                                reFetchApi={
                                    (value) => { this.reFetchApi(value) }
                                }
                            />
                            <div className="mt-3">
                                <div className="ag-theme-alpine" style={{ height: "calc(100vh - 120px)", width: "100%" }}>
                                    <Row className="mb-3" justify="space-between">
                                        <Col span={6}>
                                            <h4>Danh sách thông báo</h4>
                                            {this.state.loading ? <Spin tip="Loading..." /> : ''}
                                        </Col>
                                        <Col >
                                            {/* <Search placeholder="Tìm kiếm" enterButton="Search" size="large" loading={this.state.loading ? true : ''} onSearch={(value, event) => {
                                                this.fetchApi({
                                                    query: value
                                                });
                                            }} /> */}
                                            <Button type="primary" onClick={this.create}>Thêm mới</Button>
                                        </Col>
                                    </Row>
                                    <div style={{height:"calc(100vh - 230px)"}}>
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            onGridReady={this.onGridReady}
                                            onFirstDataRendered={this.onFirstDataRendered}
                                            frameworkComponents={this.state.frameworkComponents}
                                            rowSelection="single"
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        >
                                        </AgGridReact>
                                    </div>
                                    <Pagination {...this.props} hasNext={this.state.hasNext} onPageChange={(payload) => {
                                        this.fetchApi({
                                            query: payload
                                        })
                                    }} />
                                </div>
                            </div>
                        </>
                    )}
                </UserContext.Consumer>
            </UserContext.Provider>
        )
    }
}
export default Table