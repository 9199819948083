import React from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import routes from "./routes/index";
import Main from "./layouts/Main";
import "antd/dist/antd.css";
import "./sass/app.scss";
import Menu from "./components/Menu";
import Cookies from 'js-cookie';

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            fetching: false,
            location: window.location.pathname
        }
    }
    showContentMenu = (routes) => {
        var result = null;
        if (routes.length > 0) {
            result = routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                    />
                );
            });
        }
        return result;
    };
    render() {
        return (
            <React.StrictMode>
                <Router>
                    {!Cookies.get("token") || this.state.location === "/login" ? <Redirect to='/login' /> : <Menu />}
                    <Main>
                        {() => <Switch>
                            {this.showContentMenu(routes)}
                        </Switch>}
                    </Main>
                </Router>
            </React.StrictMode>
        )
    }
}

export default App;
