import React from "react";
import { AgGridReact } from 'ag-grid-react';
import { Row, Col, Tooltip, Button } from 'antd';
import axiosClient from "../../services/axiosClient";
import { Input } from 'antd';
import moment from "moment";
import Action from "./cell_customs/Action";
import ChangePassword from "./modal/ChangePassword";
import UpgradePackage from "./modal/UpgradePackage";
import lodash from "lodash";
import Pagination from "../../utils/Pagination";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateUser from "./modal/CreateUser";
import ChangePayment from "./modal/ChangePayment";
import ChangePasswordLevel2 from "./modal/ChangePasswordLevel2";
import ChangeAddress from "./modal/ChangeAddress";
import ChangeUsername from "./modal/ChangeUsername";

const { Search } = Input;
//Khởi tạo môt
export const UserContext = React.createContext();
ChangePassword.contextType = UserContext;
ChangePasswordLevel2.contextType = UserContext
ChangeUsername.contextType = UserContext
Action.contextType = UserContext;

// Ref
const ChangePasswordComponent = React.forwardRef((props, ref) => {
    return <ChangePassword ref={ref} {...props} />
});

const ChangePasswordLevel2Component = React.forwardRef((props, ref) => {
    return <ChangePasswordLevel2 ref={ref} {...props} />
});

const ChangeUsernameComponent = React.forwardRef((props, ref) => {
    return <ChangeUsername ref={ref} {...props} />
});

let ref = React.createRef();
class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: 'Tên',
                    field: 'fullName',
                    width: 100,
                    maxWidth: 150,
                    valueSetter: params => {
                        return false;
                    }
                },
                {
                    headerName: 'Tên tài khoản',
                    field: 'username',
                    width: 150,
                    suppressSizeToFit: true,
                    valueSetter: params => {
                        return false;
                    }
                },
                {
                    headerName: 'Điện thoại',
                    field: 'mobile',
                    width: 100,
                    suppressSizeToFit: true,
                    valueSetter: params => {
                        return false;
                    }
                },
                {
                    headerName: 'Email',
                    field: 'email',
                    width: 150,
                    suppressSizeToFit: true,
                    valueSetter: params => {
                        return false;
                    }
                },
                {
                    headerName: 'Mã ref',
                    field: 'refCode',
                    width: 80,
                    suppressSizeToFit: true,
                    valueSetter: params => {
                        return false;
                    }
                },
                {
                    headerName: 'Mã code',
                    field: 'code',
                    width: 80,
                    suppressSizeToFit: true,
                    valueSetter: params => {
                        return false;
                    }
                },
                {
                    headerName: 'Số dư',
                    field: 'balance',
                    width: 100,
                    suppressSizeToFit: true,
                    valueSetter: params => {
                        return false;
                    },
                    valueFormatter: this.currencyFormatter
                },
                {
                    headerName: 'Đóng băng',
                    field: 'frozen',
                    width: 100,
                    suppressSizeToFit: true,
                    valueSetter: params => {
                        return false;
                    },
                    valueFormatter: this.currencyFormatter
                },
                {
                    headerName: 'IP',
                    field: 'client',
                    width: 200,
                    suppressSizeToFit: true,
                    editable: false,
                    wrapText: true,
                    hide: this.props.role !== 'admin',
                    cellRendererFramework: (e) => this.renderClient(e)
                },
                {
                    headerName: 'Ngày tạo',
                    field: 'createdAt',
                    width: 150,
                    suppressSizeToFit: true,
                    valueFormatter: (params) => {
                        return moment(params.data.createdAt).format("DD/MM/YYYY");
                    },
                    editable: false,
                },
                {
                    headerName: 'Hành động',
                    field: 'action',
                    with: 700,
                    minWidth: 200,
                    cellRenderer: "Action",
                    pinned: 'right',
                    editable: false,
                },
            ],
            defaultColDef: {
                resizable: true,
                lockPosition: true,
                wrapText: true,
                editable: true,
            },
            rowData: [],
            loading: false,
            gridApi: {},
            frameworkComponents: {
                Action: Action
            },
            showPopupChangePassword: false,
            showPopupChangePassword2: false,
            showPopupChangeUsername: false,
            showPopupUpgradePackage: false,
            selectedRows: {},
            hasNext: false,
            visibleModalCreateUser: false,
            visibleModalChangePayement: false,
            visibleModalChangeAddress: false,
            originalPayment: null,
            originalAddress: null,
            /**
             * Css row where
             * https://www.ag-grid.com/javascript-grid-row-styles/
             */
            rowClassRules: {
                "blocked": params => {
                    return !params.data.active;
                }
            },
            tab: false
        }
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this)
        this.onGridReady = this.onGridReady.bind(this)
        this.currencyFormatter = this.currencyFormatter.bind(this)
    }

    renderClient = (e) => {
        const address = e?.value?.regionName ? lodash.get(e?.value, 'regionName', '') + '-' +
            lodash.get(e?.value, 'city', '') + '-' + lodash.get(e?.value, 'country', '') : 'Không xác định'
        return (
            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 1.5 }}>
                <span style={{display: 'block'}}>IP: {e?.value?.query ?? 'Không xác định'}</span>
                <span>{address}</span>
            </div>
        )
    }
    async fetchApi(params = {}) {

        this.setState({
            loading: true
        });

        const currentParams = this.getCurrentQueryParams()
        axiosClient.get(`${process.env.REACT_APP_API_ENDPOINT}/${this.props.role}/user`, {
            params: {
                ...currentParams,
                ...lodash.pick(params, 'page', 'mobile'),
                isVip: this.state.tab
            }
        }).then((resp) => {
            this.setState({
                rowData: resp.results.users.map(rs => {
                    rs['isVip'] = this.state.tab;
                    return rs;
                }),
                hasNext: resp.results.hasNext
            })
        }).catch((resp) => {

        }).finally(() => {
            this.setState({
                loading: false
            });
        })
    }
    getCurrentQueryParams() {
        const paramsQuery = (new URL(document.location)).searchParams;
        let paramsCurrentQuery = {}
        if (paramsQuery.has('page')) {
            paramsCurrentQuery['page'] = paramsQuery.get('page')
        }
        if (paramsQuery.has('username')) {
            paramsCurrentQuery['mobile'] = paramsQuery.get('mobile')
        }

        return paramsCurrentQuery
    }
    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };
    async onGridReady(params) {
        this.setState({
            gridApi: params.api
        })
        await this.fetchApi(params);
        this.state.gridApi.sizeColumnsToFit();
    }
    updateShowPopupChangePassword = (value) => {
        this.setState({
            showPopupChangePassword: value,
        });
    };
    updateShowPopupChangeUsername = (value) => {
        this.setState({
            showPopupChangeUsername: value,
        });
    };
    updateShowPopupChangePassword2 = (value) => {
        this.setState({
            showPopupChangePassword2: value,
        });
    };
    updateShowPopupUpgradePackage = (value) => {
        this.setState({
            showPopupUpgradePackage: value,
        });
    };
    onSelectionChanged = () => {
        this.setState({
            selectedRows: this.state.gridApi.getSelectedRows()
        })
    }
    editPayment = (payment) => {
        this.setState({
            visibleModalChangePayement: true,
            originalPayment: payment
        })
    }

    editAddress = (address) => {
        this.setState({
            visibleModalChangeAddress: true,
            originalAddress: address
        })
    }
    currencyFormatter(params) {
        return Math.floor(params.value)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    onTab = (isTab) => {
        if (isTab === this.state.tab) return;
        this.setState({ tab: isTab }, () => {
            this.fetchApi();
        })
    }
    render() {
        return (
            <UserContext.Provider
                value={{
                    showPopupChangePassword: this.state.showPopupChangePassword,
                    showPopupChangePassword2: this.state.showPopupChangePassword2,
                    showPopupChangeUsername: this.state.showPopupChangeUsername,
                    updateShowPopupChangePassword: this.updateShowPopupChangePassword.bind(this),
                    updateShowPopupChangePassword2: this.updateShowPopupChangePassword2.bind(this),
                    updateShowPopupChangeUsername: this.updateShowPopupChangeUsername.bind(this),
                    showPopupUpgradePackage: this.state.showPopupUpgradePackage,
                    updateShowPopupUpgradePackage: this.updateShowPopupUpgradePackage.bind(this),
                    editPayment: this.editPayment.bind(this),
                    editAddress: this.editAddress.bind(this),
                    fetchApi: this.fetchApi.bind(this),
                    selectedRows: this.state.selectedRows,
                }}
            >
                <UserContext.Consumer>
                    {() => (
                        <>
                            <ChangePayment
                                key={lodash.get(this.state.originalPayment, '_id', Math.floor(Math.random()*1000))}
                                visible={this.state.visibleModalChangePayement}
                                payment={this.state.originalPayment}
                                setVisible={(visible) => {
                                    this.setState({
                                        visibleModalChangePayement: visible,
                                        originalPayment: visible ? this.state.originalPayment : null
                                    })
                                }}
                                onUpdate={() => this.fetchApi()}
                            />
                            <ChangeAddress
                                key={lodash.get(this.state.originalAddress, '_id', Math.floor(Math.random()*1000))}
                                visible={this.state.visibleModalChangeAddress}
                                address={this.state.originalAddress}
                                setVisible={(visible) => {
                                    this.setState({
                                        visibleModalChangeAddress: visible,
                                        originalAddress: visible ? this.state.originalAddress : null
                                    })
                                }}
                                onUpdate={() => this.fetchApi()}
                            />
                            <UpgradePackage visible={this.state.showPopupUpgradePackage} />
                            <div className="pd15">
                                <div className="ag-theme-alpine" style={{ height: "calc(100vh - 120px)", width: "100%" }}>
                                    <Row className="mb-3">
                                        {/* <Col span={8}>
                                            {this.state.loading ? <Spin tip="Loading..." /> : ''}
                                        </Col> */}
                                        <Col span={6}>
                                            <Search placeholder="Tìm kiếm" size="middle" enterButton="Search" loading={this.state.loading ? true : ''} onSearch={(value, event) => {
                                                this.fetchApi({
                                                    mobile: value
                                                });
                                            }} />
                                        </Col>
                                        {this.props.role === 'admin' && !this.state.tab &&
                                            <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }} offset={15}>
                                                <Tooltip placement="bottomRight" title="Tạo">
                                                    <Button
                                                        icon={<PlusCircleOutlined />} type="primary" onClick={() => {
                                                            this.setState({
                                                                visibleModalCreateUser: true
                                                            })
                                                        }}>
                                                        Tạo
                                                    </Button>
                                                </Tooltip>
                                                <CreateUser
                                                    visible={this.state.visibleModalCreateUser}
                                                    setVisible={(visible) => {
                                                        this.setState({
                                                            visibleModalCreateUser: visible
                                                        })
                                                    }}
                                                    onUpdate={() => this.fetchApi()}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <div style={{ height: "calc(100vh - 360px)" }}>
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            onGridReady={this.onGridReady}
                                            onFirstDataRendered={this.onFirstDataRendered}
                                            frameworkComponents={this.state.frameworkComponents}
                                            rowSelection="single"
                                            onSelectionChanged={this.onSelectionChanged.bind(this)}
                                            rowHeight={100}
                                            headerHeight={37}
                                            rowClassRules={this.state.rowClassRules}
                                        >
                                        </AgGridReact>
                                    </div>
                                    <Pagination {...this.props} hasNext={this.state.hasNext} onPageChange={(payload) => {
                                        this.fetchApi(payload)
                                    }} />
                                    <ChangePasswordComponent ref={ref} />
                                    <ChangePasswordLevel2Component ref={ref} />
                                    <ChangeUsernameComponent ref={ref} />
                                </div>
                            </div>
                        </>
                    )}
                </UserContext.Consumer>
            </UserContext.Provider>
        )
    }
}
export default User