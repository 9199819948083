import React from 'react';

import { Modal, Form, Input, InputNumber, message, Alert } from 'antd';
import { useTranslation } from 'react-i18next'
import { createBonusBoxService, createUserInGameService, listBonusBoxService } from '../../services/minigame';
import _ from 'loadsh'
import { listUserService } from '../../services/user';
import DebounceSelectUser from '../Fields/DebounceSelectUser';

function CreateUserInGame(props) {
  const { visible, setVisible, onCreate } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmitForm = async () => {
    try {
      const key = "create_bonus_box"

      const values = await form.validateFields()
      message.loading({ content: t("Loading..."), key });
      setVisible(false)
      try {
        const { user, box } = values
        const response = await createUserInGameService({
          user: user?.value,
          box: box?.value
        })

        onCreate(_.get(response, 'results', ""))
        form.resetFields()
        message.success({ content: t("Create bonus success"), key });
      } catch (error) {
        console.log("error", error);
        setVisible(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  const fetchUserList = async (keyword) => {
    const response = await listUserService({
      mobile: keyword,
      queryType: 'not-level'
    })

    return _.map(_.get(response, 'results.users', []), (el) => {
      return {
        label: el?.fullName,
        value: el?._id
      }
    })
  }

  const fetchBonusBoxList = async (keyword) => {
    const response = await listBonusBoxService()

    return _.map(_.get(response, 'results', []), (el) => {
      return {
        label: el?.name,
        value: el?._id
      }
    })
  }

  return (
    <Modal
      visible={visible}
      title={t("Create UserInGame")}
      okText={t("Create")}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="user" label={t("user")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <DebounceSelectUser
            showSearch
            placeholder={t("Type to searching...")}
            fetchOptions={fetchUserList}
          />
        </Form.Item>
        <Form.Item
          name="box" label={t("box")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <DebounceSelectUser
            showSearch
            firstFetch={true}
            placeholder={t("Type to searching...")}
            fetchOptions={fetchBonusBoxList}
          />
        </Form.Item>
        {/* <Form.Item
          name="name" label={t("name")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input className="w-100" placeholder={t('Enter name')} />
        </Form.Item> */}
        {/* <Form.Item
          name="value" label={t("amount")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
            { type: "number", message: t("The input is a number") }
          ]}
        >
          <InputNumber
            placeholder={t('Enter amount')}
            className="w-100"
            formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\VND\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name="color" label={t("color")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input className="w-100" placeholder={t('Enter color')} />
        </Form.Item>
        <Form.Item
          name="percent" label={t("percent")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber 
            placeholder={t("Enter percent")}
            className="w-100"
            min="0"
          />
        </Form.Item>
        <Form.Item
          name="order" label={t("order")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber 
            placeholder={t("Enter order")}
            className="w-100"
            min="0"
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
}

export default CreateUserInGame;