import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import Cookies from 'js-cookie';
const role = Cookies.get("role");
let menusGeneral = [
  {
    name: "Trang chủ",
    to: "/",
    exact: true,
  },
]
const menus = [
  {
    name: "Nạp - Rút",
    to: "/transactions",
    exact: false,
  },
  {
    name: "Gói dịch vụ",
    to: "/packages",
    exact: false,
  },
  {
    name: "Sản phẩm",
    to: "/products",
    exact: false,
  },
  {
    name: "Cài đặt",
    to: "/settings",
    exact: false,
  },

];


if (role === 'admin') {
  menusGeneral = menusGeneral.concat(menus);
}

function fetchApi() {

}
//Custom Link
const MenuLink = ({ label, to, activeOnlyWhenExact }) => {
  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => {
        //match la doi tuong xac dinh su trung khop cua URL
        var active = match ? "active abc" : "";

        return (
          <li className={`my-li ${active}`}>
            <Link to={to} className="my-link">
              {label}
            </Link>
          </li>
        );
      }}
    />
  );
};

class Menu extends Component {
  componentDidMount() {
    fetchApi();
  }
  render() {
    return (
      <nav className="navbar navbar-default" style={{ backgroundImage: 'url(/images/bg.svg)' }}>
        <ul className="nav navbar-nav">
          <li>
            <img alt="logo" src="/images/icon_weshop.svg" style={{ maxWidth: "100%" }} />
          </li>
          {this.showMenus(menusGeneral)}
          <li>
            <a href="/login">Đăng xuất</a>
          </li>
        </ul>
      </nav>
    );
  }

  showMenus = (menus) => {
    var result = null;

    if (menus.length > 0) {
      result = menus.map((menu, index) => {
        return (
          <MenuLink
            key={index}
            label={menu.name}
            to={menu.to}
            activeOnlyWhenExact={menu.exact}
          />
        );
      });
    }

    return result;
  };
}

export default Menu;
