import React from 'react';

import { Modal, Form, Input, InputNumber, message } from 'antd';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import { updateSavingService } from '../../services/saving';

function CollectionEditFormSaving(props) {
  const { visible, setVisible, onUpdate, dataSelect } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmitForm = async () => {
    try {
      const key = "create_transaction"

      const values = await form.validateFields()
      message.loading({ content: t("Loading..."), key });
      setVisible(false)
      try {
        const response = await updateSavingService(dataSelect._id, values)
        onUpdate(_.get(response, 'results', ""))
        form.resetFields()
        message.success({ content: t("Update package success"), key });
      } catch (error) {
        setVisible(true)
      }
    } catch (error) {
    //   console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={t("Update package")}
      okText={t("Edit")}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ ...dataSelect }}
      >
        <Form.Item
          name="name" label={t("name saving")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            disabled={true}
            placeholder={t("Enter name saving")}
          />
        </Form.Item>
        <Form.Item
          name="duration" label={t("duration saving")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber
            min={0}
            className="w-100"
            placeholder={t("Enter duration saving")}
          />
        </Form.Item>
        <Form.Item
          name="balance" label={t("price") + " VND"}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber
            className="w-100"
            formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\VND\s?|(,*)/g, '')}
            placeholder={t("Enter price")}
          />
        </Form.Item>
        <Form.Item
          name="interestDay" label={t("interestDay") + " VND"}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber
            className="w-100"
            formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\VND\s?|(,*)/g, '')}
            placeholder={t("Enter interestDay")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CollectionEditFormSaving;