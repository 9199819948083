import React from "react";
import Home from "../components/Home";
import NotFound from "../components/NotFound";
import { LoginComponent } from "../components/Login";
import Withdrawn from "../components/Withdrawn/index";
import Transaction from '../components/Transaction/Transaction'
import RequestBank from "../components/Transaction/RequestBank";
import Setting from "../components/Setting";
import Notify from "../components/Notify";
import Package from "../components/Package";
import Saving from "../components/Package/Saving";
import Minigame from "../components/Minigame/Index";
import UserInGame from '../components/Minigame/UserInGame'
import History from "../components/Minigame/History";
import SettingMiniGame from '../components/Minigame/Setting'
import Lixi from "../components/Lixi/Lixi";
import RollCall from "../components/RollCall/RollCall";
import HistoryRollCall from '../components/RollCall/History';
import UserDetail from '../components/userDetail';
import Cookies from 'js-cookie';
import Order from "../components/home/Order";
import Product from "../components/Product/index";
const role = Cookies.get("role");
let routerGeneral = [
  {
    path: "/",
    exact: true,
    main: ({ match, location }) => <Home {...{ match, location }} />,
  },
  {
    path: "/user-detail",
    exact: true,
    main: ({ match, location }) => <UserDetail {...{ match, location }} />,
  },
  {
    path: "/login",
    exact: false,
    main: ({ location }) => <LoginComponent location={location} />,
  },
];

const routes = [
  {
    path: "/orders/:userId",
    exact: false,
    main: ({ match, location }) => <Order {...{ match, location }} />,
  },
  {
    path: "/withdrawns",
    exact: false,
    main: ({ match, location }) => <Withdrawn {...{ match, location }} />,
  },
  {
    path: "/requests-bank",
    exact: false,
    main: ({ match, location }) => <RequestBank {...{ match, location }} />,
  },
  {
    path: "/transactions",
    exact: true,
    main: ({ match, location }) => <Transaction {...{ match, location }} />,
  },
  {
    path: "/packages",
    exact: true,
    main: ({ match, location }) => <Package {...{ match, location }} />,
  },
  {
    path: "/saving",
    exact: true,
    main: ({ match, location }) => <Saving {...{ match, location }} />,
  },
  {
    path: "/notfound",
    exact: false,
    main: () => <NotFound />,
  },
  {
    path: "/settings",
    exact: false,
    main: ({ match, location }) => (
      <Setting match={match} location={location} />
    )
  },
  {
    path: "/notifications",
    exact: false,
    main: ({ match, location }) => (
      <Notify match={match} location={location} />
    ),
  },

  {
    path: "/minigame",
    exact: true,
    main: ({ match, location }) => <Minigame {...{ match, location }} />
  },
  {
    path: "/minigame/user-in-game",
    exact: true,
    main: ({ match, location }) => <UserInGame {...{ match, location }} />
  },
  {
    path: "/minigame/history",
    exact: true,
    main: ({ match, location }) => <History {...{ match, location }} />
  },
  {
    path: "/minigame/setting",
    exact: true,
    main: ({ match, location }) => <SettingMiniGame {...{ match, location }} />
  },
  {
    path: "/lixi",
    exact: true,
    main: ({ match, location }) => <Lixi {...{ match, location }} />
  },
  {
    path: "/products",
    exact: true,
    main: ({ match, location }) => <Product {...{ match, location }} />
  },
  {
    path: "/roll-call",
    exact: true,
    main: ({ match, location }) => <RollCall {...{ match, location }} />
  },
  {
    path: "/roll-call/history",
    exact: true,
    main: ({ match, location }) => <HistoryRollCall {...{ match, location }} />
  },
];
if (role === 'admin') {
  routerGeneral = routerGeneral.concat(routes);
}


export default routerGeneral;
