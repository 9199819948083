import axiosClient from "./axiosClient"

const prefix = '/admin/roll-call'

export const listRollCallService = async(params = {}) => {
  return await axiosClient.get(`${prefix}`, {params})
}

export const updateRollCallService = async(id, payload) => {
  return await axiosClient.put(`${prefix}/${id}`, payload)
}

export const userRollCallService = async(params = {}) => {
  return await axiosClient.get(`${prefix}/user-roll-call`, {params})
}