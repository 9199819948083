import { Col, Row, Form, Input, InputNumber, Alert, Button, Radio, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabRoute from './TabRoute';
import _ from 'loadsh'
import axiosClient from '../../services/axiosClient';
import { updateSettingMiniGameService } from '../../services/minigame';

function Setting(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [rowData, setRowData] = useState({})

  useEffect(() => {
    const init = async () => {
      const response = await axiosClient.get(`/admin/setting/minigame`)
      form.setFieldsValue(_.get(response, 'results.data', ''))
      setRowData(response?.results)
    }

    init()
  }, [])

  const handleSubmitForm = async () => {
    try {
      const key = "create_transaction"
      const values = await form.validateFields()
      message.loading({ content: t("Loading..."), key });
      try {
        console.log({values, rowData});
        await updateSettingMiniGameService(rowData?._id, values)
        message.success({ content: t("Update setting success"), key });
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <TabRoute {...props}>
      <div className="content__header w-100"></div>
      <div className="content__table ag-theme-alpine">
      <Row>
        <Col span={12}>
          <Alert className="mb-3" message="Cấu hình của minigame" type="info" />
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            >
              <Form.Item
                style={{marginBottom: '.75rem'}}
                name="active" label={t("status")}
                rules={[{ required: true, message: t("Please do not leave this field blank") }]}
              >
                <Radio.Group>
                  <Radio value={true}>Bật</Radio>
                  <Radio value={false}>Tắt</Radio>
                </Radio.Group>
                {/* <Input className="w-100" placeholder={t('Enter name')} /> */}
              </Form.Item>
             
              <Form.Item
                name="costTicket" label={t("cost ticket")}
                rules={[
                  { required: true, message: t("Please do not leave this field blank") },
                  { type: "number", message: t("The input is a number") }
                ]}
              >
                <InputNumber
                  placeholder={t('Enter cost ticket')}
                  className="w-100"
                  formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\VND\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item
                name="feeWin" label={t("feeWin")}
                rules={[
                  { required: true, message: t("Please do not leave this field blank") },
                  { type: "number", message: t("The input is a number") }
                ]}
              >
                <InputNumber
                  placeholder={t('Enter feeWin')}
                  className="w-100"
                  formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\%\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item>
                <Button onClick={handleSubmitForm} type="primary">Cập nhật</Button>
              </Form.Item>
            </Form>
        </Col>
      </Row>
      </div>
    </TabRoute>
  );
}

export default Setting;