import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Button, Tooltip, DatePicker, Input, Row, Col, Tag } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import Bank from '../Columns/Bank'
import { CheckCircleOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { listTransactionService } from "../../services/transaction";
import NumberFormat from "react-number-format";
import CreatedAt from "../Columns/CreatedAt";
import CollectionCreateForm from "./CollectionCreateForm";
import Pagination from "../../utils/Pagination";
import TabRoute from './TabRoute'
import queryString from 'query-string'
import Status from "../Columns/Status";
import Filters from "../../utils/Filters";

function Transaction(props) {
  const [rowData, setRowData] = useState(null);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }

      if(_.get(params,'query.user','').length > 11){
        _.set(params,'query.user','');
      }
      if(_.get(query,'user','').length > 11){
        _.set(query,'user','');
      }

      const response = await listTransactionService({
        ...query,
        ..._.get(params, 'query', {})
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {
      console.log('error');
    }
  };

  const onFirstDataRendered = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      if (column.colId !== 'description') {
        allColumnIds.push(column.colId);
      }
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  const handleCreateTransaction = (result) => {
    onGridReady()
  }

  return (
    <TabRoute {...props}>
      <div className="content__header w-100">
        <Filters {...props} onFiltersChange={(payload) => {
          onGridReady({
            query: payload
          })
        }} />
        <div className="content__action">
          <Tooltip placement="bottomRight" title={t('Create')}>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setVisible(true);
              }} type="primary">
              {t('Create')}
            </Button>
          </Tooltip>
          <CollectionCreateForm
            visible={visible}
            setVisible={setVisible}
            onCreate={handleCreateTransaction}
          />
        </div>
      </div>
      <div className="content__table ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
            editable:true
          }}
          onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={_.get(rowData, 'datas', [])}
        >
          {/* <AgGridColumn headerName={t("id")} suppressSizeToFit={true} field="_id" wrapText={true} /> */}
          <AgGridColumn headerName={t("user")} suppressSizeToFit={true} field="user.username" wrapText={true} valueSetter={({param})=>{
                        return false;
                    }}/>
          <AgGridColumn headerName={t("paymentType")} suppressSizeToFit={true} cellRendererFramework={Bank} field="paymentType" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("amount")} suppressSizeToFit={true} cellRendererFramework={(params) => {
              let value = params.value
              if(params.data.isCredit == false){
                value = value * -1;
              } 
            return <NumberFormat value={value} thousandSeparator={true} suffix=" USD" displayType={'text'} />
          }} field="amount" wrapText={true} valueSetter={({param})=>{
            return false;
        }}/>
          <AgGridColumn headerName={t("status")} suppressSizeToFit={true} cellRendererFramework={Status} field="status" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("description")} flex={1} minWidth={350} suppressSizeToFit={true} field="description" wrapText={true} valueSetter={({param})=>{
                        return false;
                    }}/>
          <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} suppressSizeToFit={true} field="createdAt" wrapText={true} editable={false}/>
        </AgGridReact>
      </div>
      <Pagination {...props} hasNext={rowData?.hasNext} onPageChange={(payload) => {
        onGridReady({
          query: payload
        })
      }} />
    </TabRoute>
  );
}

export default Transaction;
