import axiosClient from "./axiosClient";
import Cookies from 'js-cookie';
const role = Cookies.get("role");

const prefix = `/${role}/user`;
const queryString = require("query-string")

export const listUserService = async (query) => {
  return await axiosClient.get(prefix, {
    params: query
  })
}

export const createUserService = async (payload) => {
  return await axiosClient.post(prefix, payload)
}

export const updateUserService = async (id,payload) => {
  return await axiosClient.put(`${prefix}/${id}`, payload)
}

export const getUserService = async (id, query= null) => {
  let pathStr = prefix + '/' + id
  if(query){
    pathStr += '?'+queryString.stringify(query)
  }
  return await axiosClient.get(pathStr)
}

export const getAddressService = async (userId) => {
  return await axiosClient.get(`${prefix}/address/${userId}`)
}

export const updateAddressService = async (userId, payload) => {
  return await axiosClient.put(`${prefix}/address/${userId}`, payload)
}

