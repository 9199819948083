import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Bank(props) {
  const { t } = useTranslation()
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value
  return (
    <div>
      <Tag color="#108ee9">{t(cellValue)}</Tag>
    </div>
  );
}

export default Bank;