import React from "react";
import axiosClient from "../../services/axiosClient";
import moment from "moment";
import { UserOutlined, TransactionOutlined, UserSwitchOutlined } from '@ant-design/icons';

class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: {}
        };
        this.fetchApi = this.fetchApi.bind(this)
    }
    // , {
    //     params: {
    //         start: start,
    //         end: end
    //     }
    // }
    fetchApi(start, end) {
        axiosClient.get(`${process.env.REACT_APP_API_ENDPOINT}/${this.props.role}/dashboard`).then((resp) => {
            this.setState({
                result: resp.results
            });
        }).catch((err) => {

        }).finally(() => {

        });
    }

    componentDidMount() {
        this.fetchApi(moment().startOf('month').format('YYYY-MM-DD hh:mm'), moment().endOf('month').format('YYYY-MM-DD hh:mm'));
    }

    numberFormat = (number, limitNumber = 0) => {
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: limitNumber,
            maximumFractionDigits: limitNumber,
        });
        const _number = formatter.format(String(number));
        return !_number || _number === "NaN" ? 0 : _number;
    };

    render() {
        const { users, deposite, withdraw } = this.state?.result;
        return (
            <div className="dashboard-counter" >
                <div className="item-counter">
                    <div className="counter-header">
                        <label>Số người đăng ký</label>
                        <UserOutlined />
                    </div>
                    <div className="counter-content">
                        <span>Tổng số lượng người đăng ký: <strong>{this.numberFormat(users?.totalUser ?? 0)}</strong></span>
                        <span>Số người đăng ký hôm nay: <strong>{this.numberFormat(users?.todayUser ?? 0)}</strong></span>
                    </div>
                </div>
                <div className="item-counter">
                    <div className="counter-header">
                        <label>Thông tin nạp tiền</label>
                        <TransactionOutlined />
                    </div>
                    <div className="counter-content">
                        <span>Số lần sạc hôm nay: <strong>{this.numberFormat(deposite?.countTodayDeposite ?? 0)}</strong></span>
                        <span>Số tiền nạp tích lũy hàng ngày: <strong>{this.numberFormat(deposite?.todayDeposite ?? 0)}&nbsp;VND</strong></span>
                        <span>Tổng số tiền nạp tích lũy: <strong>{this.numberFormat(deposite?.totalDeposite ?? 0)}&nbsp;VND</strong></span>
                    </div>
                </div>
                <div className="item-counter">
                    <div className="counter-header">
                        <label>Thông tin rút tiền</label>
                        <UserSwitchOutlined />
                    </div>
                    <div className="counter-content">
                        <span>Số lần rút tiền hôm nay: <strong>{this.numberFormat(withdraw?.countTodayWithdraw ?? 0)}</strong></span>
                        <span>Số tiền rút tiền lũy hàng ngày: <strong>{this.numberFormat(withdraw?.todayWithdraw ?? 0)}&nbsp;VND</strong></span>
                        <span>Tổng số rút tiền được tích lũy: <strong>{this.numberFormat(withdraw?.totalWithdraw ?? 0)}&nbsp;VND</strong></span>
                    </div>
                </div>
            </div>
        )
    }
}
export default Counter