import axios, { CancelToken } from "axios";
import queryString from "query-string";
import _ from "lodash";
import Cookies from 'js-cookie';
import { message } from "antd";

let cancel = [];

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        "content-type": "application/json",
    },
    paramsSerializer: (params) => queryString.stringify(params),
});
axiosClient.interceptors.request.use(async (config) => {
    let requestName =
        config.url.replace("/", "_") + (_.keys(config.params).join("_") || "");
    if (requestName) {
        if (cancel[requestName] && cancel[requestName].cancel) {
            cancel[requestName].cancel(`Cancel request ${requestName} !!`);
        }
        config.cancelToken = new CancelToken((c) => {
            cancel[requestName] = {};
            cancel[requestName].cancel = c;
        });
    }
    config.headers["Authorization"] = "Bearer " + (await Cookies.get("token"));
    config.headers["Sec-Fetch-Mode"] = "no-cors";
    config.headers["Pragma"] = "no-cache";
    return {
        ...config,
    };
});
axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            const message = _.get(response, "data.message");

            if (!!message) {
                console.log({ message });
            }
            return response.data;
        }
        return response;
    },
    async (error) => {
        if (axios.isCancel(error)) {
            console.log("Request canceled", error);
        } else {
            if (!!error.response) {
                const status = _.get(error, "response.data.statusCode", 400);
                const messageAlert = _.get(error, "response.data.message", "");
                switch (status) {
                    case 401:
                        // await Cookies.remove("token");
                        // window.location.href = '/login';
                        console.log({ message });
                        break;

                    case 403:
                        console.log({ message });
                        break;

                    case 422:
                        const errors = _.get(error, "response.data.message", {});
                        console.log({ errors });
                        _.each(errors, async (value) => {
                            await message.error({ content: _.get(value, '[0]', '') })
                        })
                        break;

                    default:
                        console.log({ message });
                        message.error({ content: messageAlert })
                        break;
                }
            }
        }
        throw error;
    }
);
export default axiosClient;
