import axiosClient from "./axiosClient"

const prefix = '/admin/product'

export const listProductService = async(params = {}) => {
  return await axiosClient.get(`${prefix}`, {params})
}

export const updateProductService = async (id, payload) => {
    return await axiosClient.put(`${prefix}/${id}`, payload)
}

export const createProductService = async (payload) => {
  return await axiosClient.post(`${prefix}`, payload)
}

export const uploadImage = (formData) =>{
  return axiosClient.post(`public/upload`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
  