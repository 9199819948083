import React, { useState, useRef } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Button, Tooltip, Badge, Modal, Tag } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import CreatedAt from "../Columns/CreatedAt";
import TabRoute from './TabRoute'
import { destroyBonusBoxService, listBonusBoxService } from "../../services/minigame";
import queryString from 'query-string'
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
// import CreateMiniGame from "../Form/CreateMiniGame";
import EditMiniGame from "../Form/EditMiniGame";
import { detailLuckeyMoneyService, listLuckyMoneyService } from "../../services/lucky";
import Pagination from "../../utils/Pagination";
import DetailLucky from "../Form/DetailLucky";
import CreateLucky from "../Form/CreateLucky";

function Lixi(props) {
  const [rowData, setRowData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [visibleCreate, setVisibleCreate] = useState(false)
  const [dataSelect, setDataSelect] = useState({})
  const item = useRef(null);
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }

      const response = await listLuckyMoneyService({
        ...query,
        ..._.get(params, 'query', {})
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {}
  };

  const onFirstDataRendered = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      if (['name', 'color', 'value'].indexOf(column.colId) == -1) {
        allColumnIds.push(column.colId);
      }
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  // const handleDestroy = async(data) => {
  //   Modal.confirm({
  //     title: 'Bạn có chắc chắn muốn xóa?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'Vui lòng xác nhận để có thể xóa',
  //     okText: 'Đồng ý',
  //     okType: 'danger',
  //     cancelText: 'Hủy',
  //     async onOk() {
  //       await destroyBonusBoxService(data._id)
  //       onGridReady()
  //     }
  //   });
  // }

  return (
    <TabRoute {...props}>
      <div className="content__header w-100">
        <div className="content__action" style={{width: '100%'}}>
          <Tooltip placement="bottomRight" title={t('Create')}>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setVisibleCreate(true);
              }} 
              type="primary"
            >
              {t('Create')}
            </Button>
          </Tooltip>
          <CreateLucky
            visible={visibleCreate}
            setVisible={() => {
              setVisibleCreate(false);
              item.current = null;
            }}
            onCreate={() => onGridReady()}
            item={item.current}
          />
        </div>
      </div>
      <div className="content__table ag-theme-alpine">
        <DetailLucky visible={visible} setVisible={setVisible} data={dataSelect} key={_.get(dataSelect, '_id', 1)} />
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
            editable:true
          }}
          // onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={rowData?.datas}
        >
          <AgGridColumn headerName={t("Số lượng bao lì xì")} flex={1} suppressSizeToFit={true} editable={false} cellRendererFramework={({value}) => {
            return <Badge
              count={value} 
              style={{ backgroundColor: '#52c41a' }}
            />
          }}  field="issue" wrapText={true} editable={false}/>
          <AgGridColumn headerName={t("Số tiền")} flex={1}  suppressSizeToFit={true} 
            cellRendererFramework={({value}) => {
              return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
            }} field="balance" editable={false} wrapText={true} 
          />
          <AgGridColumn headerName={t("status")} suppressSizeToFit={true} minWidth={150} cellRendererFramework={({ value }) => {
            if (!value) {
              return <Tag color="red">
                <ExclamationCircleOutlined /> Tạm hoãn
              </Tag>
            }
            return <Tag color="success">
              <CheckCircleOutlined /> Phát hành
            </Tag>
          }} field="active" editable={false} wrapText={true} />
          <AgGridColumn headerName={t("createdAt")} width={250} cellRendererFramework={CreatedAt} suppressSizeToFit={true} field="createdAt" wrapText={true}  editable={false}/>
          <AgGridColumn editable={false} headerName={t("action")}
            width={110}
            cellStyle={{
              textAlign: 'center'
            }}
            cellRendererFramework={({ data }) => {
              return <>
                <Tooltip placement="topLeft" title={t('Xem chi tiết')} arrowPointAtCenter>
                  <Button style={{ backgroundColor: "#faad14", borderColor: "#faad14" }} type="primary" onClick={async () => {
                    const response = await detailLuckeyMoneyService(data?._id)
                    await setDataSelect(response?.results)
                    await setVisible(true)
                  }} icon={<EyeOutlined />}></Button>
                </Tooltip>
                <Tooltip placement="topLeft" title={t('Sửa')} arrowPointAtCenter>
                  <Button style={{ backgroundColor: "#faad14", borderColor: "#faad14", marginLeft: 10 }} type="primary"
                    onClick={() => {
                      item.current = data;
                      setVisibleCreate(true)
                    }} icon={<EditOutlined />}></Button>
                </Tooltip>
              </>
            }} pinned="right"
          />
        </AgGridReact>
      </div>
      <Pagination {...props} hasNext={rowData?.hasNext} onPageChange={(payload) => {
        onGridReady({
          query: payload
        })
      }} />
    </TabRoute>
  );
}

export default Lixi;