import axiosClient from "./axiosClient"

const prefix = '/admin/rotation-lucky'

export const listBonusBoxService = async(params = {}) => {
  return await axiosClient.get(`${prefix}/box`, {params})
}

export const createBonusBoxService = async(payload) => {
  return await axiosClient.post(`${prefix}/box`, payload)
}

export const updateBonusBoxService = async(id, payload) => {
  return await axiosClient.put(`${prefix}/box/${id}`, payload)
}

export const destroyBonusBoxService = async(id) => {
  return await axiosClient.delete(`${prefix}/box/${id}`)
}

export const listUserInGameService = async(params = {}) => {
  return await axiosClient.get(`${prefix}/user-in-rotation`, {params})
}

export const createUserInGameService = async(payload) => {
  return await axiosClient.post(`${prefix}/user-in-rotation`, payload)
}

export const disableUserIngameService = async(id) => {
  return await axiosClient.put(`${prefix}/disable-user-in-rotation/${id}`)
}

export const listHistoryService = async(params) => {
  return await axiosClient.get(`${prefix}/user-history`, {params})
}

export const detailSettingMiniGameService = async() => {
  return await axiosClient.get(`/admin/setting/minigame`)
}

export const updateSettingMiniGameService = async(id, payload) => {
  return await axiosClient.put(`/admin/setting/${id}`, payload)
}