import axiosClient from "./axiosClient"

const prefix = '/admin/payment'

export const listPaymentService = async (query) => {
  return await axiosClient.get(prefix, {
    params: query
  })
}

export const updatePaymentService = async (id, payload) => {
  return await axiosClient.put(`${prefix}/${id}`, payload)
}