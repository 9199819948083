import axiosInstance from "./axiosClient";
import Cookies from 'js-cookie';

export const authService = {
    login,
    logout,
};

function logout() {
    Cookies.remove('token');
    Cookies.remove('role');
}

async function login(phone, password) {
    const response = await axiosInstance.post(`${process.env.REACT_APP_API_ENDPOINT}/authentication/login`, { mobile: phone, password: password, deviceID: 123456 });
    Cookies.set('token', response.results.access_token);
    if (response?.results?.roles) {
        const isAdmin = response?.results?.roles.find(role => role === 'admin');
        Cookies.set('role', isAdmin ? 'admin' : 'agent');
    }
    window.location.href = '/';
    return response.results.access_token;
}