import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string'
import _ from 'lodash';

function Pagination({ hasNext, onPageChange, location }) {
  const { t } = useTranslation()
  const history = useHistory()

  const query = queryString.parse(location.search);

  const handleOnPageChange = (type = 'next') => {
    let newPage = _.get(query, 'page', 1)
    if (type == 'next') {
      newPage = hasNext ? parseInt(newPage) + 1 : newPage
    } else {
      newPage = newPage <= 1 ? newPage : parseInt(newPage) - 1
    }

    onPageChange({
      ...query,
      page: newPage
    })
    history.push(`${location?.pathname}?${queryString.stringify({
      ...query,
      page: newPage
    })}`)
  }

  return (
    <div className="content__pagination">
      <Button
        type="primary"
        onClick={() => handleOnPageChange("prev")}
        icon={<DoubleLeftOutlined />}
        style={{ marginRight: '.75rem' }}
        disabled={!query?.page || query?.page <= 1}
      >
        {t("Previous")}
      </Button>
      <Button
        onClick={() => handleOnPageChange()}
        type="primary"
        disabled={!hasNext}
      >
        {t("Next")} <DoubleRightOutlined />
      </Button>
    </div>
  );
}

export default Pagination;