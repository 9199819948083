import { Descriptions, Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Payment(props) {
  const { t } = useTranslation();
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value

  return (
    <Descriptions className="component__user">
      <Descriptions.Item span={3}>
        <Tag color="#108ee9">{t(cellValue?.nameBank)}</Tag>
      </Descriptions.Item>
      <Descriptions.Item span={3} label={t("accountName")}>
        {cellValue?.fullName}
      </Descriptions.Item>
      <Descriptions.Item span={3} label={t("accountNumber")}>
        {cellValue?.numberBank}
      </Descriptions.Item>
      <Descriptions.Item span={3} style={{ paddingBottom: "unset" }} label={t("accountBank")}>
        {cellValue?.nameBank}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default Payment;