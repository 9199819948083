import React from "react";
import _ from 'loadsh'

import { useTranslation } from "react-i18next";
import { Tabs } from 'antd';
import { AlignLeftOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

const { TabPane } = Tabs;

function TabRoute(props) {
  const { t } = useTranslation()
  const history = useHistory()

  const handleChangeTab = (e) => {
    history.push(e)
  }

  return (
    <div className="content p-0">
      <Tabs defaultActiveKey={props?.location?.pathname} onChange={handleChangeTab}>
        <TabPane
          tab={
            <span>
              <AlignLeftOutlined />
              {t('box')}
            </span>
          }
          key="/roll-call"
        >
        </TabPane>
        <TabPane
          tab={
            <span>
              <AlignLeftOutlined />
              {t('History')}
            </span>
          }
          key="/roll-call/history"
        >
        </TabPane>
      </Tabs>
      <div className="content p-0">
        {props.children.map(child => {
          return child;
        })}
      </div>
    </div>
  );
}

export default TabRoute;
