import React from "react";
import Table from "../components/Setting/Table";
class Setting extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
  render() {
    return <div className="">
        <Table location={this.props.location}/>
    </div>;
  }
}

export default Setting;
