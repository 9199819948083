import React, { useEffect, useState } from 'react';
import { getUserService } from '../services/user';
import { useHistory } from 'react-router-dom';
import { Collapse, Button, Tooltip, Table } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const UserDetail = (props) => {
  const history = useHistory();
  const [detail, setDetail] = useState(null);
  const id = props?.location?.state?.id;
  const { Panel } = Collapse;
  const columns = [
    {
      title: 'Họ và tên',
      dataIndex: 'fullName',
      key: "fullName"
    },
    {
      title: 'SĐT',
      dataIndex: 'mobile',
      key: "mobile"
    },
    {
      title: 'Tổng nạp',
      dataIndex: 'topup',
      render: vl => <span>{numberFormat(vl)}&nbsp;VND</span>,
      key: "topup"
    },
    {
      title: 'Tổng rút',
      dataIndex: 'withdraw',
      render: vl => <span>{numberFormat(vl)}&nbsp;VND</span>,
      key: "withdraw"
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'bonus',
      render: vl => <span>{numberFormat(vl)}&nbsp;VND</span>,
      key: "bonus"
    },
  ]

  useEffect(() => {
    if (!id) {
      history.goBack();
      return;
    }
    getUser(id);
  }, [])

  const getUser = async (id) => {
    const data = await getUserService(id);
    setDetail(data?.results);
  }

  const renderHeader = (title, data) => {
    return (
      <div className='ant-collapse-header-item'>
        <label>{title}</label>
        <div className="percent"><span>Tỷ lệ: {data.percent}%</span></div>
      </div>
    )
  }

  const numberFormat = (number, limitNumber = 0) => {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: limitNumber,
      maximumFractionDigits: limitNumber,
    });
    const _number = formatter.format(String(number));
    return !_number || _number === "NaN" ? 0 : _number;
  };


  const renderContent = (item) => {
    if (!item) return;
    const users = item.users.map(rs => {
      rs.key = rs._id;
      return rs;
    });

    return (
      <div className="ant-collapse-content-item">
        <div className="content-item-group">
          <div>
            <label>Hoa hồng nhóm:&nbsp;</label>
            <span>{numberFormat(item?.totalBonus)}&nbsp;VND</span>
          </div>
          <div>
            <label>Tổng nạp của nhóm:&nbsp;</label>
            <span>{numberFormat(item?.totalTopUp)}&nbsp;VND</span>
          </div>
          <div>
            <label>Tổng rút của nhóm:&nbsp;</label>
            <span>{numberFormat(item?.totalWithDraw)}&nbsp;VND</span>
          </div>
        </div>
        <Table columns={columns} dataSource={users} />
      </div>
    )
  }
  if (!id || !detail) return null;

  return (
    <div className="user-detail-page">
      <div className="action-bar pd10">
        <Tooltip title="Quay lại">
          <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()} />
        </Tooltip>
      </div>
      <Collapse defaultActiveKey={['1', '2', '3']}>
        <Panel header={renderHeader('Cấp 1', detail?.level1)} key="1">
          {renderContent(detail?.level1)}
        </Panel>
        <Panel header={renderHeader('Cấp 2', detail?.level2)} key="2">
          {renderContent(detail?.level2)}
        </Panel>
        <Panel header={renderHeader('Cấp 3', detail?.level3)} key="3">
          {renderContent(detail?.level3)}
        </Panel>
      </Collapse>
    </div>
  );
};

export default UserDetail;