import React, { useEffect } from 'react';
import { Button, Tooltip, DatePicker, Input, Row, Col, Tag } from "antd";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import queryString from 'query-string'
import { useHistory } from 'react-router-dom';

function Filters({ location, onFiltersChange }) {
  const { t } = useTranslation()
  const query = queryString.parse(location.search);
  const history = useHistory()

  const handleSearch = _.debounce(function (e) {
    console.log(e);
    onFiltersChange({
      ...query,
      mobile: e?.target?.value
    })
    history.push(`${location?.pathname}?${queryString.stringify({
      ...query,
      mobile: e?.target?.value
    })}`)
  }, 500)

  return (
    <div className="content__filter">
      <Row gutter={16}>
        <Col className="gutter-row" span={4}>
          <Input defaultValue={query?.mobile} allowClear onChange={handleSearch} placeholder={t('Type to searching...')} />
        </Col>
        {/* <Col className="gutter-row" span={6}>
          <DatePicker.RangePicker className="w-100" placeholder={t('Date')} />
        </Col> */}
      </Row>
    </div>
  );
}

export default Filters;