import React from "react";

function Main({ children }) {
    return (
        <div className="app__layout h-full">
            {children()}
        </div >
    );
}

export default Main;
