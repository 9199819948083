import React from "react";
import { Modal, Input, message, Form, Tabs, Switch, Space, Button, InputNumber, Row, Col } from 'antd';
import axiosClient from "../../services/axiosClient";
import lodash from "lodash";
import moment from "moment";
import { useTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

function Popup(props) {
    const { t } = useTranslation();
    const { showPopup, updateShowPopup, reFetchApi, selectedRows } = props;
    const [loading, setLoading] = React.useState(false)
    const [activeForm, setActiveForm] = React.useState(1)
    const [form] = Form.useForm();
    const formRef = React.createRef();
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            await saveData(lodash.get(selectedRows, '_id', ''), values);

            await reFetchApi(true);
        } catch (error) {
            console.log(error);
        }
    };

    function convertToSlug(Text) {
        return Text
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            ;
    }

    const saveData = async (id, values) => {
        let data = {
            // "name": lodash.get(values, 'name', ''),
            // "slug": convertToSlug(lodash.get(values, 'name', '')),
            // "data": {
            "fee": lodash.get(values, 'fee', ''),
            "times": {
                "from": moment(lodash.get(values, 'from', '00:00')).format("HH:mm"),
                "to": moment(lodash.get(values, 'to', '00:00')).format("HH:mm"),
            },
            "timesInboud": lodash.get(values, 'timesInboud', ''),
            "timeOfDay": lodash.get(values, 'timeOfDay', ''),
            // }
        }
        let dataMiniGame = {
            "active": lodash.get(values, 'active', ''),
            "rate": {
                "tai": lodash.get(values, 'tai', 0),
                "xiu": lodash.get(values, 'xiu', 0),
            },
            "timeSession": lodash.get(values, 'timeSession', ''),
            "fee": lodash.get(values, 'fee', ''),
        }
        setLoading(true);
        if (id) {
            console.log(lodash.get(props, 'selectedRows.slug', ''));
            if (lodash.get(props, 'selectedRows.slug', '') === 'minigame') {
                data = dataMiniGame;
            }
            await axiosClient.put(`${process.env.REACT_APP_API_ENDPOINT}/admin/setting/${id}`, lodash.get(values, 'fee', '') ? data : values).then((resp) => {
                message.success(resp.message);
            }).catch((err) => {
                message.error(err.message);
            }).finally(() => {
                setLoading(false);
                handleCancel();
            });
        } else {
            await axiosClient.post(`${process.env.REACT_APP_API_ENDPOINT}/admin/setting`, lodash.get(values, 'fee', '') ? data : values).then((resp) => {
                message.success(resp.message);
            }).catch((err) => {
                message.error(err.message);
            }).finally(() => {
                setLoading(false);
                handleCancel();
            });
        }
    }

    const handleCancel = async () => {
        form.resetFields();
        updateShowPopup(false, {});
    };

    function callback(key) {
        setActiveForm(key);
    }
    return (
        <div>
            <Modal
                title="Cài đặt"
                visible={showPopup}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={handleCancel}
                width="50vw"
                okText={t("Edit")}
                cancelText={t("Cancel")}
            >
                <Tabs onChange={callback} defaultActiveKey={lodash.get(selectedRows, '_id', '') ? lodash.get(selectedRows, 'data.fee', '') ? "1" : "2" : ""}>
                    {/* Tab Withdraw */}
                    {lodash.get(selectedRows, 'slug', '') === 'withdraw-policy' ?
                        <TabPane tab="Thông tin rút tiền" key="1">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                {/* <Form.Item
                                name="name"
                                label="Tên:"
                                rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                            >
                                <Input disabled placeholder="Tên" />
                            </Form.Item> */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.fee', '')}
                                    name="fee"
                                    label="Phí"
                                    rules={[{ required: true, message: 'Vui lòng nhập fee' }]}
                                >
                                    <Input placeholder="Fee" />
                                </Form.Item>

                                {/* <Form.Item
                                initialValue={moment(`1970-01-01 ${lodash.get(selectedRows, 'data.times.from', '00:00')}`)}
                                name="from"
                                label="Thời gian từ"
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian' }]}
                            >
                                <TimePicker />
                            </Form.Item>

                            <Form.Item
                                initialValue={moment(`1970-01-01 ${lodash.get(selectedRows, 'data.times.to', '00:00')}`)}
                                name="to"
                                label="Thời gian tới"
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian' }]}
                            >
                                <TimePicker />
                            </Form.Item> */}

                                {/* <Form.Item
                                initialValue={lodash.get(selectedRows, 'data.timesInboud', '')}
                                name="timesInboud"
                                label="Thời gian đến"
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian' }]}
                            >
                                <Input placeholder="timesInboud" />
                            </Form.Item>

                            <Form.Item
                                initialValue={lodash.get(selectedRows, 'data.timeOfDay', '')}
                                name="timeOfDay"
                                label="Thời gian của ngày"
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian' }]}
                            >
                                <Input placeholder="Times Of Day" />
                            </Form.Item> */}
                            </Form>
                        </TabPane>
                        : ''}
                    {lodash.get(selectedRows, 'slug', '') === 'bank-tranfer-info' ?
                        <TabPane tab="Tạo tài khoản ngân hàng" key="2">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                {/* <Form.Item
                                name="name"
                                label="Tên"
                                rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                            >
                                <Input disabled placeholder="Tên" />
                            </Form.Item> */}
                                {/* Họ tên */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.fullName', '')}
                                    name="fullName"
                                    label="Họ tên"
                                    rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}
                                >
                                    <Input placeholder="fullName" />
                                </Form.Item>
                                {/* Số tài khoản */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.bankNumber', '')}
                                    name="bankNumber"
                                    label="Số tài khoản"
                                    rules={[{ required: true, message: 'Vui lòng nhập số tài khoản' }]}
                                >
                                    <Input placeholder="bankNumber" />
                                </Form.Item>
                                {/* Tên ngân hàng */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.bankName', '')}
                                    name="bankName"
                                    label="Tên ngân hàng"
                                    rules={[{ required: true, message: 'Vui lòng nhập Tên ngân hàng' }]}
                                >
                                    <Input placeholder="bankName" />
                                </Form.Item>
                                {/* Mô tả */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.description', '')}
                                    name="description"
                                    label="Mô tả"
                                    rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}
                                >
                                    <Input placeholder="description" />
                                </Form.Item>
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.walletAddress', '')}
                                    name="walletAddress"
                                    label="Dia chi vi"
                                    rules={[{ required: true, message: 'Vui lòng nhập dia chi vi' }]}
                                >
                                    <Input placeholder="walletAddress" />
                                </Form.Item>
                            </Form>
                        </TabPane>
                        : ''}
                    {/* Thông báo ngắn */}
                    {lodash.get(selectedRows, 'slug', '') === 'short-notification' ?
                        <TabPane tab="Thông báo ngắn" key="3">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.description', '')}
                                    name="description"
                                    label="Mô tả"
                                    rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}
                                >
                                    <Input placeholder="description" />
                                </Form.Item>
                            </Form>
                        </TabPane>
                        : ''}
                    {/* Thông tin liên hệ */}
                    {lodash.get(selectedRows, 'slug', '') === 'info-contact' ?
                        <TabPane tab="Thông tin liên hệ" key="4">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.homePage', '')}
                                    name="homePage"
                                    label="Website"
                                    rules={[{ required: true, message: 'Vui lòng nhập Website' }]}
                                >
                                    <Input placeholder="Nhập Website" />
                                </Form.Item>
                                {/* linkChat */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.linkChat', '')}
                                    name="linkChat"
                                    label="Link Chat"
                                    rules={[{ required: true, message: 'Vui lòng nhập link chat' }]}
                                >
                                    <Input placeholder="linkChat" />
                                </Form.Item>
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.image_link', '')}
                                    name="image_link"
                                    label="Hình ảnh"
                                    rules={[{ required: true, message: 'Vui lòng nhập hình ảnh' }]}
                                >
                                    <Input placeholder="Nhập hình ảnh" />
                                </Form.Item>
                                {/* Tools */}
                                <h4>#Cài đặt</h4>
                                <Form.List
                                    name="tools"
                                    initialValue={lodash.get(selectedRows, `data.tools`, [])}
                                >
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field) => (
                                                <Space key={field.key}
                                                    style={{ display: '', marginBottom: 3, width: '30%' }}
                                                    className={lodash.get(selectedRows, `data.tools[${field.key}].type`, '') === 'support' ? 'w-100' : ''}
                                                    align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        initialValue={lodash.get(selectedRows, `data.tools[${field.key}].show`, '')}
                                                        name={[field.key, lodash.get(selectedRows, `data.tools[${field.key}].type`, '') !== 'support' ? 'show' : 'link']}
                                                        label={lodash.get(selectedRows, `data.tools[${field.key}].name`, '')}
                                                        fieldKey={[field.fieldKey, lodash.get(selectedRows, `data.tools[${field.key}].type`, '') !== 'support' ? 'show' : 'link']}
                                                        {...lodash.get(selectedRows, `data.tools[${field.key}].type`, '') !== 'support' && { valuePropName: "checked" }}
                                                    >
                                                        {lodash.get(selectedRows, `data.tools[${field.key}].type`, '') === 'support' ?
                                                            <Input placeholder={lodash.get(selectedRows, `data.tools[${field.key}].name`, '')}
                                                                className="w-100" />
                                                            :
                                                            <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                                                        }
                                                    </Form.Item>
                                                </Space>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </Form>
                        </TabPane>
                        : ''}
                    {/* Tiện ích */}
                    {lodash.get(selectedRows, 'slug', '') === 'utilities' ?
                        <TabPane tab="Tiện ích" key="5">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                {/* SDT */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.groupName', '')}
                                    name="groupName"
                                    label="Tên nhóm"
                                    rules={[{ required: true, message: 'Vui lòng nhập số tên' }]}
                                >
                                    <Input placeholder="Tên" />
                                </Form.Item>
                                {/* Trạng thái */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.show', '')}
                                    name="show"
                                    label="Trạng thái"
                                    rules={[{ required: true, message: 'Vui lòng nhập lựa chọn' }]}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                                </Form.Item>
                                <label>Danh sách tiện ích</label>
                                <Form.List
                                    name="list"
                                    initialValue={lodash.get(selectedRows, `data.list`, [])}
                                >
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field) => (
                                                <Space key={field.key} style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        initialValue={lodash.get(selectedRows, `data.list[${field.key}].name`, '')}
                                                        name={[field.name, 'name']}
                                                        fieldKey={[field.fieldKey, 'name']}
                                                        rules={[{ required: true, message: 'Bạn chưa nhập tên' }]}
                                                    >
                                                        <Input placeholder="Tên" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...field}
                                                        initialValue={lodash.get(selectedRows, `data.list[${field.key}].name`, '')}
                                                        name={[field.name, 'link']}
                                                        fieldKey={[field.fieldKey, 'link']}
                                                        rules={[{ required: true, message: 'Bạn chưa nhập link' }]}
                                                    >
                                                        <Input placeholder="Link" />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Thêm
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Form>
                        </TabPane>
                        : ''}
                    {/* Mini game */}
                    {lodash.get(selectedRows, 'slug', '') === 'minigame' ?
                        <TabPane tab="Mini Game" key="6">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                {/* Trạng thái */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.active', '')}
                                    name="active"
                                    label="Trạng thái"
                                    // rules={[{ required: true, message: 'Vui lòng nhập lựa chọn' }]}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                                </Form.Item>
                                <label>Tỉ lệ tài xỉu</label>
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.rate.tai', '')}
                                    name="tai"
                                    label="Tài"
                                    rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.rate.xiu', '')}
                                    name="xiu"
                                    label="Xỉu"
                                    rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.fee', '')}
                                    name="fee"
                                    label="Phí"
                                    rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.timeSession', '')}
                                    name="timeSession"
                                    label="Thời gian"
                                    rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </Form>
                        </TabPane>
                        : ''}
                    {lodash.get(selectedRows, 'slug', '') === 'roll-call' ?
                        <TabPane tab="Đăng nhập" key="8">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                {/* Trạng thái */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.active', '')}
                                    name="active"
                                    label="Trạng thái"
                                    // rules={[{ required: true, message: 'Vui lòng nhập lựa chọn' }]}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                                </Form.Item>
                            </Form>
                        </TabPane>
                        : ''}
                    {lodash.get(selectedRows, 'slug', '') === 'package-policy' ?
                        <TabPane tab="Package Policy" key="8">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                {/* Trạng thái */}
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.feeCancel', '')}
                                    name="feeCancel"
                                    label="Phí bán"
                                    // rules={[{ required: true, message: 'Vui lòng nhập lựa chọn' }]}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                            </Form>
                        </TabPane>
                        : ''}
                    {lodash.get(selectedRows, 'slug', '') === 'withdraw-policy-v2' &&
                        <TabPane tab="Rút tiền" key="7">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col md={12}>
                                        <Form.Item
                                            initialValue={lodash.get(selectedRows, 'data.milestone', '')}
                                            name="milestone"
                                            label="Số tiền mốc phí rút"
                                            rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            initialValue={lodash.get(selectedRows, 'data.minWithdraw', '')}
                                            name="minWithdraw"
                                            label="Số tiền tối thiểu"
                                            rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            initialValue={lodash.get(selectedRows, 'data.timeOfDay', '')}
                                            name="timeOfDay"
                                            label="Giới hạn số lần rút trong ngày"
                                            rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col md={12}>
                                        <Form.Item
                                            initialValue={lodash.get(selectedRows, 'data.feeUp', '')}
                                            name="feeUp"
                                            label="Phí rút tiền trên mốc"
                                            rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            initialValue={lodash.get(selectedRows, 'data.feeDown', '')}
                                            name="feeDown"
                                            label="Phí rút tiền dưới mốc"
                                            rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </TabPane>
                    }
                    {lodash.get(selectedRows, 'slug', '') === 'support' ?
                        <TabPane tab="Hỗ trợ khách hàng" key="2">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                <Form.Item

                                    initialValue={lodash.get(selectedRows, 'data.linkZalo', '')}

                                    name="linkZalo"

                                    label="Link zalo"

                                    rules={[{ required: true, message: 'Vui lòng nhập link zalo' }]}

                                    >

                                    <Input placeholder="linkZalo" />

                                    </Form.Item>

                                    {/* Tên ngân hàng */}

                                    <Form.Item

                                    initialValue={lodash.get(selectedRows, 'data.linkTelegram', '')}

                                    name="linkTelegram"

                                    label="Link telegram"

                                    rules={[{ required: true, message: 'Vui lòng nhập link telegram' }]}

                                    >

                                    <Input placeholder="linkTelegram" />

                                </Form.Item>

                            </Form>
                        </TabPane>
                        : ''}
                    {lodash.get(selectedRows, 'slug', '') === 'invite-friend' ?
                        <TabPane tab="Mời bạn đầu tư" key="2">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.active', '')}
                                    name="active"
                                    label="Trạng thái"
                                    // rules={[{ required: true, message: 'Vui lòng nhập lựa chọn' }]}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                                </Form.Item>
                                <Form.Item

                                    initialValue={lodash.get(selectedRows, 'data.reward', '')}

                                    name="reward"

                                    label="reward"

                                    rules={[{ required: true, message: 'Vui lòng nhập gia tri' }]}

                                    >

                                    <InputNumber className="w-100" placeholder="reward" />

                                </Form.Item>

                            </Form>
                        </TabPane>
                        : ''}
                    {lodash.get(selectedRows, 'slug', '') === 'bonus-register' ?
                        <TabPane tab="Thưởng đăng ký" key="10">
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                ref={formRef}
                                initialValues={{ ...selectedRows }}
                            >
                                <Form.Item
                                    initialValue={lodash.get(selectedRows, 'data.active', '')}
                                    name="active"
                                    label="Trạng thái"
                                    // rules={[{ required: true, message: 'Vui lòng nhập lựa chọn' }]}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                                </Form.Item>
                                <Form.Item

                                    initialValue={lodash.get(selectedRows, 'data.reward', '')}

                                    name="reward"

                                    label="reward"

                                    rules={[{ required: true, message: 'Vui lòng nhập gia tri' }]}

                                    >

                                    <InputNumber className="w-100" placeholder="reward" />

                                </Form.Item>

                            </Form>
                        </TabPane>
                        : ''}

                </Tabs>
            </Modal>
        </div >
    )
}

export default Popup;