import { AgGridColumn, AgGridReact } from "ag-grid-react";
import _ from "lodash";
import React, { useState } from 'react';
import Filters from '../../utils/Filters';
import queryString from 'query-string'
import { useTranslation } from "react-i18next";
import { listPackageService } from "../../services/package";
import NumberFormat from "react-number-format";
import { CheckCircleOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Tag, Tooltip } from "antd";
import CreatedAt from "../Columns/CreatedAt";
import CollectionEditForm from "./CollectionEditForm";
import TabRoute from "./TabRoute";

function Package(props) {
  const [rowData, setRowData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [tab, setTab] = useState("forever");
  const [dataSelect, setDataSelect] = useState({})
  const { t } = useTranslation()

  const onGridReady = async (params) => {
    try {
      let query = queryString.parse(props?.location?.search)

      if (!query) {
        query = {}
      }
      query.type = tab
      const response = await listPackageService({
        ...query,
        ..._.get(params, 'query', {})
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
    } catch (error) {
      console.log('error');
    }
  };

  const getListPackage = async (type) =>{
    try {
      if(tab == type){
        return
      }
      let query = queryString.parse(props?.location?.search)
      if (!query) {
        query = {}
      }
      query.type = type
      const response = await listPackageService({
        ...query
      })
      const results = _.get(response, 'results', {});
      setRowData(results)
      setTab(type)
    } catch (error) {
      console.log('error');
    }
  }

  const onFirstDataRendered = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      if (column.colId !== 'description') {
        allColumnIds.push(column.colId);
      }
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  return (
    <TabRoute {...props}>
      <CollectionEditForm
        key={_.get(dataSelect, '_id', 1)}
        visible={visible}
        dataSelect={dataSelect}
        setVisible={setVisible}
        onUpdate={() => {
          setDataSelect({})
          onGridReady()
        }}
      />
      <div className="content__table ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPosition: true,
            wrapText: true,
            resizable: true,
          }}
          onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          headerHeight="36"
          floatingFiltersHeight="10"
          onGridReady={onGridReady}
          rowData={rowData}
        >
          <AgGridColumn headerName={t("name")}  suppressSizeToFit={true} field="name" wrapText={true} />
          <AgGridColumn headerName={t("price")} suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value} thousandSeparator={true} suffix=" USD" displayType={'text'} />
          }} field="price" wrapText={true} />
          <AgGridColumn headerName={t("interestDay")} suppressSizeToFit={true} cellRendererFramework={({ value }) => {
            return <NumberFormat value={value} thousandSeparator={true} suffix=" %" displayType={'text'} />
          }} field="interestPercent" wrapText={true} />

          <AgGridColumn headerName={t("Tổng đơn hàng")} suppressSizeToFit={true} field="totalOrder" wrapText={true} />
          <AgGridColumn headerName={t("status")} suppressSizeToFit={true} minWidth={150} cellRendererFramework={({ value }) => {
            if (!value) {
              return "Đã đóng"
            }
            return "Đang mở"

          }} field="status" wrapText={true} />
          <AgGridColumn cellStyle={{
            textAlign: 'center'
          }} headerName={t("action")}
            width={110}
            cellRendererFramework={({ data }) => {
              return <Tooltip placement="topLeft" title={t('Edit')} arrowPointAtCenter>
                <Button style={{ backgroundColor: "#faad14", borderColor: "#faad14" }} type="primary" onClick={async () => {
                  await setDataSelect(data)
                  await setVisible(true)
                }} icon={<EditOutlined />}></Button>
              </Tooltip>
            }} pinned="right"
          />
        </AgGridReact>
      </div>
    </TabRoute >
  );
}

export default Package;