import React, { useState } from 'react';

import { Modal, Form, Input, Radio, Select, InputNumber, message } from 'antd';
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import DebounceSelectUser from '../Fields/DebounceSelectUser';
import { listUserService } from '../../services/user';
import { _ } from 'ag-grid-community';
import { createTransactionService } from '../../services/transaction';

function CollectionCreateForm(props) {
  const { visible, setVisible, onCreate } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmitForm = async () => {
    try {
      const key = "create_transaction"

      const values = await form.validateFields()
      message.loading({ content: t("Loading..."), key });
      setVisible(false)
      try {
        const response = await createTransactionService({
          ...values,
          amount: values?.amount ? parseInt(values?.amount) : 0,
          user: values?.user?.value
        })
        onCreate(_.get(response, 'results', ""))
        form.resetFields()
        message.success({ content: t("Create transaction success"), key });
      } catch (error) {
        console.log("error", error);
        setVisible(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  const fetchUserList = async (keyword) => {
    const response = await listUserService({
      username: keyword,
      queryType: "not-level"
    })

    return _.map(_.get(response, 'results.users', []), (el) => {
      return {
        label: el?.fullName || el?.username,
        value: el?._id
      }
    })
  }

  return (
    <Modal
      visible={visible}
      title={t("Create transaction")}
      okText={t("Create")}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          isCredit: true,
        }}
      >
        <Form.Item
          name="user" label={t("user")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <DebounceSelectUser
            showSearch
            placeholder={t("Type to searching...")}
            fetchOptions={fetchUserList}
          />
        </Form.Item>
        <Form.Item
          name="paymentType" label={t("Loại giao dịch")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Select
            placeholder={t("Choose payment type")}
          >
            <Select.Option value="bank">{t("bank")}</Select.Option>
            <Select.Option value="frozen">{t("Đóng băng")}</Select.Option>
            <Select.Option value="package">{t("package")}</Select.Option>
            <Select.Option value="bonus-order">{t("bonus")}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="status" label={t("status")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Select
            placeholder={t("Choose status")}
          >
            <Select.Option value="pending">{t("pending")}</Select.Option>
            <Select.Option value="approved">{t("approved")}</Select.Option>
            <Select.Option value="reject">{t("reject")}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="amount" label={t("amount")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
            { type: "number", message: t("The input is a number") }
          ]}
        >
          {/* <Input
            placeholder={t('Enter amount')}
            className="w-100"
          /> */}
          <InputNumber
            placeholder={t('Enter amount')}
            className="w-100"
            formatter={value => `USD ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\USD\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name="description" label={t("description")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
            { min: 10, message: t("Must have at least 10 characters") },
          ]}
        >
          <TextArea rows={4}
            placeholder={t("Enter description")}
          />
        </Form.Item>
        <Form.Item name="isCredit">
          {/* <Checkbox>{t("isCredit")}</Checkbox> */}
        <Radio.Group>
            <Radio value={true}>Cộng tiền</Radio>
            <Radio value={false}>Trừ tiền</Radio>
        </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CollectionCreateForm;