import React, { useState } from 'react';

import { Modal, Form, Input, InputNumber, message, Radio } from 'antd';
import { useTranslation } from 'react-i18next'
import { updatePackageService } from '../../services/package';
import _ from 'lodash';

function CollectionEditForm(props) {
  const { visible, setVisible, onUpdate, dataSelect } = props
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmitForm = async () => {
    try {
      const key = "create_transaction"

      const values = await form.validateFields()
      message.loading({ content: t("Loading..."), key });
      setVisible(false)
      try {
        const response = await updatePackageService(dataSelect._id, values)
        onUpdate(_.get(response, 'results', ""))
        form.resetFields()
        console.log({ values });
        message.success({ content: t("Update package success"), key });
      } catch (error) {
        console.log("error", error);
        setVisible(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCancelForm = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={t("Update package")}
      okText={t("Edit")}
      width={700}
      cancelText={t("Cancel")}
      onCancel={handleCancelForm}
      onOk={handleSubmitForm}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ ...dataSelect }}
      >
        <Form.Item
          name="name" label={t("name")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            placeholder={t("Enter name")}
          />
        </Form.Item>
        <Form.Item
          name="location" label={t("DDiaj chỉ")}
          rules={[{ required: true, message: t("Please do not leave this field blank") }]}
        >
          <Input
            placeholder={t("Enter name")}
          />
        </Form.Item>
        <Form.Item
          name="price" label={t("price")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
            { type: "number", message: t("The input is a number") }
          ]}
        >
          <InputNumber
            placeholder={t('Enter amount')}
            className="w-100"
            formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\VND\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name="duration" label={t("duration")}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber
            min={0}
            className="w-100"
            placeholder={t("Enter duration")}
          />
        </Form.Item>
        <Form.Item
          name="interestPercent" label={t("Tổng lợi nhuận") + " %"}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber
            className="w-100"
            placeholder={t("Enter interestPercent")}
          />
        </Form.Item>
        <Form.Item
          name="interestDay" label={t("interestDay") + " VND"}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber
            className="w-100"
            formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\VND\s?|(,*)/g, '')}
            placeholder={t("Enter interestDay")}
          />
        </Form.Item>
        <Form.Item
          name="timeBuy" label={'Số lần mua gói'}
          rules={[
            { required: true, message: t("Please do not leave this field blank") },
          ]}
        >
          <InputNumber
            min={0}
            className="w-100"
            placeholder={t("Enter timeBuy")}
          />
        </Form.Item>
        <Form.Item name="status" label={'Trạng thái'}>
          <Radio.Group>
              <Radio value={true}>Còn máy</Radio>
              <Radio value={false}>Hết máy</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CollectionEditForm;