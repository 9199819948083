import React, { Component } from 'react';
import { Descriptions, Badge } from 'antd';
import lodash from 'lodash';


export default class CellData extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Descriptions title="" layout="">
                {/* Thông itn rút tiền */}
                 {lodash.get(this.props,'data.data.fee','') ? <Descriptions.Item span={3} contentStyle={{paddingBottom:"0"}} label="Phí">{lodash.get(this.props,'data.data.fee','')}</Descriptions.Item> : ''}
                 {/* {lodash.get(this.props,'data.data.times','') ? <Descriptions.Item span={3} label="Thời gian">{lodash.get(this.props,'data.data.times.from','')} - {lodash.get(this.props,'data.data.times.to','')}</Descriptions.Item> : ''} */}
                 {/* {lodash.get(this.props,'data.data.timesInboud','') ? <Descriptions.Item span={3} label="Thời gian đến">{lodash.get(this.props,'data.data.timesInboud','')}</Descriptions.Item> : ''} */}
                 {/* {lodash.get(this.props,'data.data.timeOfDay','') ? <Descriptions.Item span={3} label="Thòi gian của ngày">{lodash.get(this.props,'data.data.timeOfDay','')}</Descriptions.Item> : ''} */}
                 {/* Bank */}
                 {lodash.get(this.props,'data.data.fullName','') ? <Descriptions.Item span={3} label="Tên">{lodash.get(this.props,'data.data.fullName','')}</Descriptions.Item> : ''}
                 {lodash.get(this.props,'data.data.bankNumber','') ? <Descriptions.Item span={3} label="Số TK">{lodash.get(this.props,'data.data.bankNumber','')}</Descriptions.Item> : ''}
                 {lodash.get(this.props,'data.data.bankName','') ? <Descriptions.Item span={3} label="Tên ngân hàng">{lodash.get(this.props,'data.data.bankName','')}</Descriptions.Item> : ''}
                 {lodash.get(this.props,'data.data.walletAddress','') ? <Descriptions.Item span={3} label="Địa chỉ ví">{lodash.get(this.props,'data.data.walletAddress','')}</Descriptions.Item> : ''}
                 {lodash.get(this.props,'data.data.description','') ? <Descriptions.Item span={3} label="Mô tả">{lodash.get(this.props,'data.data.description','')}</Descriptions.Item> : ''}
                 {/* Thông tin liên hệ */}
                 {lodash.get(this.props,'data.data.phone','') ? <Descriptions.Item span={3} label="SDT">{lodash.get(this.props,'data.data.phone','')}</Descriptions.Item> : ''}
                 
                 {lodash.get(this.props,'data.data.linkTelegram','') ? <Descriptions.Item span={3} label="Link Telegram">{lodash.get(this.props,'data.data.linkTelegram','')}</Descriptions.Item> : ''}
                 {lodash.get(this.props,'data.data.linkZalo','') ? <Descriptions.Item span={3} label="Link zalo">{lodash.get(this.props,'data.data.linkZalo','')}</Descriptions.Item> : ''}
                 {lodash.get(this.props,'data.data.feeCancel','') ? <Descriptions.Item span={3} label="Phí bán">{lodash.get(this.props,'data.data.feeCancel','')} %</Descriptions.Item> : ''}
            </Descriptions>
        );
    }
}
