import React from "react";
import Table from "./Notify/Table";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

function callback(key) {
    console.log(key);
}
class Notity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return <div className="">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Thông báo" key="1">
                    <Table location={this.props.location}/>
                </TabPane>
                {/* <TabPane tab="Thông báo ngắn" key="2">
                    <Table />
                </TabPane> */}
            </Tabs>
        </div>;
    }
}

export default Notity;
