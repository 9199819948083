import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { Modal, Tooltip, DatePicker, Input, Row, Col, Tag, Button, message, Descriptions, Image } from "antd";
import _ from 'loadsh'

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, CheckCircleTwoTone, ExclamationCircleOutlined, PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { listRequestBankSerivce, listTransactionService, updateStatusBankService } from "../../services/transaction";
import NumberFormat from "react-number-format";
import CreatedAt from "../Columns/CreatedAt";
import TabRoute from "./TabRoute";
import Pagination from "../../utils/Pagination";
import queryString from 'query-string'
import Status from "../Columns/Status";
import Filters from "../../utils/Filters";
// import { AllCommunityModules } from '@ag-grid-community/all-modules';

function RequestBank(props) {
    const [rowData, setRowData] = useState(null);
    const { t } = useTranslation()
    const onGridReady = async (params) => {
        try {
            let query = queryString.parse(props?.location?.search)

            if (!query) {
                query = {}
            }
            const response = await listRequestBankSerivce({
                ...query,
                ..._.get(params, 'query', {})
            })
            const results = _.get(response, 'results', {});
            setRowData(results)
        } catch (error) {
            console.log('error');
        }
    };

    const showPromiseConfirm = ({ data, rowIndex }, status) => {
        Modal.confirm({
            title: t('Do you want to approval these items?'),
            icon: <ExclamationCircleOutlined />,
            content: t('Please confirm before approval this item'),
            async onOk() {
                try {
                    const response = await updateStatusBankService(data?._id, status)
                    onGridReady()
                    message.success(t('Approval request transaction success'));
                } catch (error) {
                    console.log("error", error);
                }
            }
        });
    }

    return (
        <TabRoute {...props}>
            <div className="content__header w-100">
                <Filters {...props} onFiltersChange={(payload) => {
                    onGridReady({
                        query: payload
                    })
                }} />
            </div>
            <div className="content__table ag-theme-alpine">
                <AgGridReact
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        lockPosition: true,
                        wrapText: true,
                        editable:true
                    }}
                    rowHeight={130}
                    animateRows={true}
                    headerHeight="36"
                    floatingFiltersHeight="10"
                    onGridReady={onGridReady}
                    rowData={_.get(rowData, 'trans', [])}
                >
                    <AgGridColumn headerName={t("user")} field="user.mobile" wrapText={true} valueSetter={({param})=>{
                        return false;
                    }}/>
                    <AgGridColumn headerName={t("nameBank")} width={250} wrapText={true} cellRendererFramework={(params) => {
                        return (
                            <Descriptions className="component__user">
                                <Descriptions.Item span={3}>
                                    <Tag color="#108ee9">{t(params.data.nameBank)}</Tag>
                                </Descriptions.Item>
                                <Descriptions.Item span={3}>
                                    Tên chủ tài khoản: {params.data.fullname}
                                </Descriptions.Item>
                                <Descriptions.Item span={3}>
                                    Số tài khoản: {params.data.numberAccount}
                                </Descriptions.Item>
                                <Descriptions.Item span={3}>
                                    Tên ngân hàng: {params.data.nameBank}
                                </Descriptions.Item>
                            </Descriptions>)
                    }} field="nameBank" wrapText={true} editable={false}/>
                    
                    <AgGridColumn headerName={t("amount")} cellRendererFramework={({ value }) => {
                        return <NumberFormat value={value} thousandSeparator={true} suffix=" đ" displayType={'text'} />
                    }} field="amount" valueSetter={({param})=>{
                        return false;
                    }}/>
                    <AgGridColumn headerName="Hình ảnh"  width={200} cellRendererFramework={({ value }) => {
                        return value ? <Image  height={100} src={value} />  : <p>Not Image</p>
                    }} field="proof" valueSetter={({param})=>{
                        return false;
                    }}/>
                    <AgGridColumn headerName={t("status")} width={150} cellRendererFramework={Status} field="status" editable={false}/>
                    
                    <AgGridColumn headerName={t("createdAt")} cellRendererFramework={CreatedAt} field="createdAt" editable={false}/>
                    <AgGridColumn cellStyle={{
                        textAlign: 'center'
                    }} headerName={t("action")}
                        width={130}
                        cellRendererFramework={(dataCell) => {
                            return (
                                <div>
                                    <Tooltip placement="topLeft" title={t('Approval')} arrowPointAtCenter>
                                        <Button style={{ marginRight: '.5rem' }} disabled={_.get(dataCell, 'data.status', 'pending') != 'pending'} type="primary" onClick={() => showPromiseConfirm(dataCell, 'approved')} icon={<CheckCircleTwoTone />}></Button>
                                    </Tooltip>
                                    <Tooltip placement="topLeft" title={t('Reject')} arrowPointAtCenter>
                                        <Button disabled={_.get(dataCell, 'data.status', 'pending') != 'pending'} type="primary" onClick={() => showPromiseConfirm(dataCell, 'reject')} icon={<CloseCircleOutlined />} danger></Button>
                                    </Tooltip>
                                </div>
                            )
                        }} pinned="right" editable={false}
                    />
                </AgGridReact>
            </div>
            <Pagination {...props} hasNext={rowData?.hasNext} onPageChange={(payload) => {
                onGridReady({
                    query: payload
                })
            }} />
        </TabRoute>
    );
}

export default RequestBank;
