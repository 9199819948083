import React from "react";
import { AgGridReact } from 'ag-grid-react';
import { Spin, Row, Col, } from 'antd';
import axiosClient from "../../services/axiosClient";
import moment from "moment";
import Action from "./Action";
import CellData from "./CellData";
import lodash from "lodash";
import Pagination from "../../utils/Pagination";
import Popup from "./Popup";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const UserContext = React.createContext();
Action.contextType = UserContext;
Popup.contextType = UserContext;

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: 'Tên',
                    field: 'name',
                    width: "100"
                },
                {
                    headerName: 'Nội dung',
                    field: 'data',
                    cellRenderer: 'CellData'
                },
                {
                    headerName: 'Ngày tạo',
                    field: 'createdAt',
                    width: 150,
                    suppressSizeToFit: true,
                    valueFormatter: (params) => {
                        return moment(params.data.createAt).format("DD/MM/YYYY");
                    }
                },
                {
                    headerName: 'Hành động',
                    field: 'action',
                    width: 50,
                    cellRenderer: "Action",
                    pinned: 'right'
                },
            ],
            defaultColDef: { resizable: true, height: 'auto' },
            rowData: [],
            loading: false,
            gridApi: {},
            frameworkComponents: {
                Action: Action,
                CellData: CellData
            },
            showPopup: false,
            selectedRows: {},
            hasNext: false,
        }
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this)
        this.onGridReady = this.onGridReady.bind(this)
        this.create = this.create.bind(this)

    }
    create() {
        this.setState({
            selectedRows: {},
            showPopup: true
        })
    }
    reFetchApi(value) {
        if (value) {
            this.fetchApi();
        }
    }
    fetchApi(params) {
        axiosClient.get(`${process.env.REACT_APP_API_ENDPOINT}/admin/setting`, {
            params: lodash.get(params, 'query', {})
        }).then((resp) => {
            const disabled = ['withdraw-policy', 'minigame'];
            this.setState({
                rowData: resp.results.filter(rs => !disabled.find(slug => slug === rs.slug)),
                hasNext: resp.results.hasNext ?? false
            })
        }).catch((resp) => {

        }).finally(() => {
            this.setState({
                loading: false
            });
        })
    }
    componentDidMount() {

    }
    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };
    async onGridReady(params) {
        this.setState({
            gridApi: params.api
        })
        await this.fetchApi(params);
        this.state.gridApi.sizeColumnsToFit();
    }
    updateShowPopup = async (value, data = {}) => {
        await this.setState({
            selectedRows: data
        });
        await this.setState({
            showPopup: value,
        });
    };
    onSelectionChanged = () => {
        this.setState({
            selectedRows: this.state.gridApi.getSelectedRows()
        })
    };

    render() {
        return (
            <UserContext.Provider
                value={{
                    showPopup: this.state.showPopup,
                    updateShowPopup: this.updateShowPopup.bind(this),
                    reFetchApi: this.reFetchApi.bind(this),
                    selectedRows: this.state.selectedRows
                }}
            >
                <UserContext.Consumer>
                    {() => (
                        <>
                            <Popup
                                key={lodash.get(this.state.selectedRows, '_id', 1)}
                                showPopup={this.state.showPopup}
                                selectedRows={this.state.selectedRows}
                                updateShowPopup={this.updateShowPopup}
                                reFetchApi={
                                    (value) => { this.reFetchApi(value) }
                                }
                            />
                            <div className="mt-3">
                                <div className="ag-theme-alpine" style={{ height: "calc(100vh - 120px)", width: "100%" }}>
                                    <Row className="mb-3" justify="space-between">
                                        <Col span={6}>
                                            <h4>Danh sách cài đặt</h4>
                                            {this.state.loading ? <Spin tip="Loading..." /> : ''}
                                        </Col>
                                        <Col >
                                            {/* <Button type="primary" onClick={this.create}>Thêm mới</Button> */}
                                        </Col>
                                    </Row>
                                    <div style={{ height: "calc(100vh - 130px)" }}>
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            onGridReady={this.onGridReady}
                                            onFirstDataRendered={this.onFirstDataRendered}
                                            frameworkComponents={this.state.frameworkComponents}
                                            rowSelection="single"
                                            rowHeight={150}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        >
                                        </AgGridReact>
                                    </div>
                                    <Pagination {...this.props} hasNext={this.state.hasNext} onPageChange={(payload) => {
                                        this.fetchApi({
                                            query: payload
                                        })
                                    }} />
                                </div>
                            </div>
                        </>
                    )}
                </UserContext.Consumer>
            </UserContext.Provider>
        )
    }
}
export default Table