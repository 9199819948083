import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Checkbox, Spin } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { userActions } from '../redux/actions/auth';

class LoginComponent extends Component {

    constructor(props, context) {
        super(props, context);

        this.props.logout();

        this.state = {
            loading: false,
            phone: "",
            password: "",
            submitted: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.onLogin = this.onLogin.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onLogin() {
        // this.setState({submitted: true, loading: true});
        const { phone, password } = this.state;
        if (phone && password) {
            this.props.login(phone, password);
        }
    }

    render() {
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            cssEase: "linear",
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        const { loading } = this.state;
        return (
            <Spin className="login-loading" tip="Đang xử lý..." delay={200} spinning={loading} indicator={antIcon}>
                <section className="login w-100 h-full">
                    <div className="login__image">
                        <Slider {...settings}>
                            <div>
                                <img src="/images/login.png" alt="Login" />
                            </div>
                            <div>
                                <img src="/images/login1.png" alt="Login" />
                            </div>
                            <div>
                                <img src="/images/login2.png" alt="Login" />
                            </div>
                            <div>
                                <img src="/images/login3.png" alt="Login" />
                            </div>
                        </Slider>
                    </div>
                    <div className="login--form">
                        <Form
                            name="formLogin"
                            className="login__form"
                            initialValues={{ remember: true }}
                            onFinish={this.onLogin}
                        >
                            <h1 className="login__title">DKNY CMS</h1>

                            <Form.Item
                                name="phone"
                                rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
                            >
                                <Input
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Số điện thoại"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                            >
                                <Input
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Mật khẩu"
                                />
                            </Form.Item>
                            <Form.Item className="login__form__action">
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Nhớ tài khoản</Checkbox>
                                </Form.Item>

                                <a className="login-form-forgot" href="">
                                    Quên mật khẩu?
                                </a>
                            </Form.Item>

                            <Form.Item className="login__form__submit">
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Đăng nhập
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </section>
            </Spin>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.auth;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginComponent);

export { connectedLoginPage as LoginComponent };