import React from "react";
import { Modal, Button, Input, Space,message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import axiosClient from "../../../services/axiosClient";
import  lodash from "lodash";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            password:"",
            confirmPassword:"",
            loading:false,
        }
        this.handleOk = this.handleOk.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleOk = () => {
        if(this.state.password && this.state.confirmPassword && this.context.selectedRows.length){
            this.saveData(this.context.selectedRows[0]._id,this.state.password,this.state.confirmPassword);
        }
    };

    saveData = (id,password,confirmPassword)=>{
        this.setState({
            loading:true
        })
        axiosClient.put(`${process.env.REACT_APP_API_ENDPOINT}/admin/user/change-password/${id}`,{
            password:password,
            confirmPassword:confirmPassword,
        }).then((resp)=>{
            message.success(resp.results.message);
        }).catch((err)=>{
            message.error(err.results.message);
        }).finally(()=>{
            this.setState({
                loading:false
            })
        });
    }

    handleCancel = () => {
        this.context.updateShowPopupChangePassword(false)
    };

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
    render() {
        return (
            <div>
                <Modal
                    title="Đổi mật khẩu"
                    visible={this.context.showPopupChangePassword}
                    onOk={this.handleOk}
                    confirmLoading={this.state.loading}
                    onCancel={this.handleCancel}
                >
                        <h4>{lodash.get(this.context,"selectedRows[0].fullName","")}</h4>
                        <label>Mật khẩu:</label>
                        <Input.Password placeholder="input password" value={this.state.password} name="password"  onChange={this.handleChange}/>
                        <label>Nhập lại mật khẩu:</label>
                        <Input.Password
                            placeholder="input password"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            value={this.state.confirmPassword}
                            name="confirmPassword"
                            onChange={this.handleChange}
                        />
                </Modal>
            </div>
        )
    }
}

export default ChangePassword;