import axiosClient from "./axiosClient"

const prefix = `${process.env.REACT_APP_API_ENDPOINT}/admin/transaction`

export const listTransactionService = async (query) => {
  return await axiosClient.get(prefix, {
    params: query
  })
}

export const listRequestBankSerivce = async (query) => {
  return await axiosClient.get(`${prefix}/request-bank`, {
    params: query
  })
}

export const updateStatusBankService = async (transactionID, status = 'approved') => {
  return await axiosClient.put(`${prefix}/verify-request-bank/${transactionID}`, {
    status
  })
}

export const createTransactionService = async (payload) => {
  return await axiosClient.post(prefix, payload)
}
