import axiosClient from "./axiosClient"

const prefix = '/admin/package'

export const listPackageService = async (query = {}) => {
  return await axiosClient.get(prefix, {
    params: query
  })
}

export const packageDetailService = async (id) => {
  return await axiosClient.get(`${prefix}/${id}`)
}

export const updatePackageService = async (id, payload) => {
  return await axiosClient.put(`${prefix}/${id}`, payload)
}

export const configPackageOrder = (payload) => {
  return axiosClient.post(`${prefix}/config-user-package`,payload)
}

export const configJackpotOrder = (payload) => {
  return axiosClient.post(`${prefix}/jackpot-order`,payload)
}

export const cancelOrderJackpot = (orderId) => {
  return axiosClient.get(`${prefix}/order-cancel/${orderId}`)
}

export const bypassFrozen = (orderId) => {
  return axiosClient.put(`${prefix}/order-frozen-bypass/${orderId}`)
}

export const getListOrderUser = (userId) => {
  return axiosClient.get(`${prefix}/order/${userId}`)
}
