import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Select, Spin } from 'antd';
import  lodash from "lodash";
import { configPackageOrder, listPackageService } from "../../../services/package";
import { _ } from 'ag-grid-community';
import { useTranslation } from 'react-i18next'
import { UserContext } from "../User";
import { useHistory } from 'react-router-dom';

function UpgradePackage(props) {
    const [form] = Form.useForm()
    const { t } = useTranslation();
    const history = useHistory()
    const [pkgs , setPkgs] = useState([])
    const [spinning , setSpinning] = useState(false)
    const context = useContext(UserContext)
    const fetchPackageList = async () => {
        const response = await listPackageService()
        const pk = _.map(_.get(response, 'results', []), (el) => {
          return {
            label: el?.name,
            value: el?._id
          }
        })
        setPkgs(pk)
    }
    const handleCancelForm = () => {
        if(spinning) return
        form.resetFields()
        context.updateShowPopupUpgradePackage(false)
    }
    const onSubmitData = async () => {
        if(spinning) return
        const values = await form.validateFields()
        const userId = lodash.get(context,"selectedRows[0]._id",null)
        setSpinning(true)
        try {
            await configPackageOrder({
                ...values,
                user: userId
            })
            history.push(`/orders/${userId}`)
        } catch (error) {
            console.log("====", error)
        } finally {
            setSpinning(false)
        }
    }
    useEffect(()=>{
        fetchPackageList()
    },[])

    return (
        <div>
            <Modal
                title="Nâng giang hàng"
                visible={lodash.get(context,"showPopupUpgradePackage",false)}
                onCancel={handleCancelForm}
                onOk={onSubmitData}
            >
                <Spin tip="Đang tạo đơn hàng cho user này, điều này có thể mất thời gian" spinning={spinning} size="large">
                <h4>{lodash.get(context,"selectedRows[0].fullName","")}</h4>
                    <h4>Giang hàng hiện tại: {lodash.get(context,"selectedRows[0].package.name","Chưa có")}</h4>
                    <label>Chọn giang hàng:</label>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                        isCredit: true,
                        }}
                    >
                        <Form.Item
                            name="package" label={t("Giang hàng")}
                            rules={[{ required: true, message: t("Please do not leave this field blank") }]}
                        >
                            <Select>
                                {pkgs.map(pk => (
                                    <Select.Option value={pk.value} key={pk.value}>{pk.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Spin>
                    
            </Modal>
        </div>
    )
    
}

export default UpgradePackage;