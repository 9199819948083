import React, { useEffect } from "react";
import { Modal,Form, InputNumber, Spin } from 'antd';
import { useState } from "react";
import { OrderContext } from "../Order";
import  lodash from "lodash";
import { configJackpotOrder } from "../../../services/package";
function ConfigOrder(props) {
    const context = React.useContext(OrderContext)
    const [spinning, setSpinning] = useState(false)
    const [form] = Form.useForm()

    const handleCancelForm = () => {
        if(spinning) return
        form.resetFields()
        context.updateShowPopupConfigOrder(false)
    }
    const onSubmitData = async () => {
        
        try {
            if(spinning) return
            const values = await form.validateFields()
            const orderId = lodash.get(context,"selectedRows[0]._id",null)
            const userId = lodash.get(context,"userId",null)
            const dataRequest = {jackpots: [{...values,orderId}],user: userId}
            setSpinning(true)
            await configJackpotOrder(dataRequest)
            context.updateShowPopupConfigOrder(false)
            context.refreshOrder()
        } catch (error) {
            console.log("====", error)
        } finally {
            setSpinning(false)
        }
    }
    useEffect(()=> {
        const dataRow = lodash.get(context,"selectedRows[0]",null)
        if(dataRow){
            form.setFieldsValue({
                topup: dataRow.topup || 0,
                commission: dataRow.commission
            })
        }
    },[lodash.get(context,"selectedRows[0]",null)])
    
    return (
        <div>
            <Modal
                title="Cấu hình đơn hàng"
                visible={lodash.get(context,"showPopupConfigOrder",false)}
                width={700}
                onCancel={handleCancelForm}
                onOk={onSubmitData}
            >
                <Spin tip="Đang cấu hình cho đơn hàng" spinning={spinning} size="large">
                    <h4>Đơn hàng số: <strong>{lodash.get(context,"selectedRows[0].orderCode","Chưa có")}</strong></h4>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                    >
                        <Form.Item
                            name="topup" label="Số tiền đơn hàng"
                            rules={[{ required: true, message: "Please do not leave this field blank" }]}
                        >
                            <InputNumber
                                placeholder={'Enter amount'}
                                className="w-100"
                                formatter={value => `USD ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\USD\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                        <Form.Item
                            name="commission" label="Số tiền được thưởng"
                            rules={[{ required: true, message: "Please do not leave this field blank" }]}
                        >
                            <InputNumber
                                placeholder={'Enter amount'}
                                className="w-100"
                                formatter={value => `USD ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\USD\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    )
}

export default ConfigOrder;