import { Modal, Table, Tag, Space } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';

function DetailLucky({visible, setVisible, data}) {
  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Họ & Tên',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: 'createdAt'
    }
  ];

  return (
    <Modal
        className="modal__custom"
        title="Danh sách người dùng trúng"
        visible={visible}
        width={1000}
        footer={null}
        onCancel={() => {
          setVisible(false)
        }}
      >
      <Table 
        columns={columns} 
        dataSource={_.get(data, 'users', [])}
        pagination={false}
      />
    </Modal>
  );
}

export default DetailLucky;