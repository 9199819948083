import React, { useState } from 'react';

import { Modal, Form, Input, InputNumber, message } from 'antd';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { createUserService } from '../../../services/user';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

function CreateUser(props) {
    const { visible, setVisible, onUpdate } = props
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const handleSubmitForm = async () => {
        try {
            const key = "create_user"

            const values = await form.validateFields()
            message.loading({ content: t("Loading..."), key });
            setVisible(false)
            try {
                const response = await createUserService(values)
                onUpdate(_.get(response, 'results', ""))
                form.resetFields()
                message.success({ content: t("Create user success"), key });
            } catch (error) {
                console.log("error", error);
                setVisible(true)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleCancelForm = () => {
        form.resetFields()
        setVisible(false)
    }

    return (
        <Modal
            visible={visible}
            title={t("Create User")}
            okText={t("Create")}
            width={700}
            cancelText={t("Cancel")}
            onCancel={handleCancelForm}
            onOk={handleSubmitForm}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    isAdmin: false,
                    isTest: false
                }}
            >
                <Form.Item
                    name="fullName" label={t("fullName")}
                    rules={[{ required: true, message: t("Please do not leave this field blank") }]}
                >
                    <Input
                        placeholder={t("Enter fullName")}
                    />
                </Form.Item>
                <Form.Item
                    name="username" label={t("username")}
                    rules={[{ required: true, message: t("Please do not leave this field blank") }]}
                >
                    <Input
                        placeholder={t("Enter username")}
                    />
                </Form.Item>
                <Form.Item
                    name="mobile" label={t("mobile")}
                    rules={[{ required: true, message: t("Please do not leave this field blank") }]}
                >
                    <Input
                        placeholder={t("Enter mobile")}
                    />
                </Form.Item>
                <Form.Item
                    name="email" label={t("email")}
                    rules={[
                        { required: true, message: t("Please do not leave this field blank") },
                        { type: 'email', message: t("Email invalid") }
                    ]}
                >
                    <Input
                        placeholder={t("Enter email")}
                    />
                </Form.Item>
                <Form.Item
                    name="password" label={t("password")}
                    rules={[{ required: true, message: t("Please do not leave this field blank") }]}
                >
                    <Input.Password
                        type="password"
                        placeholder={t("Enter password")}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    name="passwordLevel2" label={t("Password Level 2")}
                    rules={[{ required: true, message: t("Please do not leave this field blank") }]}
                >
                    <Input.Password
                        type="password"
                        placeholder={t("Enter password")}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    name="refCode" label={t("refCode")}
                >
                    <Input
                        placeholder={t("Enter refCode")}
                    />
                </Form.Item>
                <Form.Item name="isAdmin" valuePropName="checked">
                    <Checkbox>{t("isAdmin")}</Checkbox>
                </Form.Item>
                <Form.Item name="isTest" valuePropName="checked">
                    <Checkbox>{t("isTest")}</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateUser;