import React from "react";
import { Modal, Input, message, Form } from 'antd';
import axiosClient from "../../services/axiosClient";
import lodash from "lodash";
import { useTranslation } from 'react-i18next'

function Popup(props) {
    const { showPopup, updateShowPopup, reFetchApi, selectedRows } = props;
    const [loading,setLoading] = React.useState(false)
    const [form] = Form.useForm();
    const formRef = React.createRef();
    const { t } = useTranslation();
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         visible: false,
    //         confirmLoading: false,
    //         title: lodash.get(this.props, 'selected[0].title', ''),
    //         description: lodash.get(this.props, 'selected[0].description', ''),
    //         loading: false,
    //     }
    //     this.handleOk = this.handleOk.bind(this);
    //     this.handleChange = this.handleChange.bind(this);
    // }

    // componentDidUpdate() {
    // }
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            await saveData(lodash.get(selectedRows, '_id', ''), values.title, values.description);
            await reFetchApi(true);
        } catch (error) {
            console.log(error);
        }
    };

    const saveData = async (id, title, description) => {
        setLoading(true);
        if (id) {
            await axiosClient.put(`${process.env.REACT_APP_API_ENDPOINT}/admin/notification/${id}`, {
                title: title,
                description: description,
            }).then((resp) => {
                message.success(resp.message);
            }).catch((err) => {
                message.error(err.results.message);
            }).finally(() => {
                setLoading(false);
                handleCancel();
            });
        } else {
            await axiosClient.post(`${process.env.REACT_APP_API_ENDPOINT}/admin/notification`, {
                title: title,
                description: description,
                isPrivate: false,
                to: [],
                highLight: true
            }).then((resp) => {
                message.success(resp.message);
            }).catch((err) => {
                message.error(err);
            }).finally(() => {
                setLoading(false);
                handleCancel();
            });
        }
    }

    const handleCancel = async() => {
        form.resetFields();
        updateShowPopup(false,{});
    };

    return (
        <div>
            <Modal
                title="Thông báo"
                visible={showPopup}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={handleCancel}
                okText={t("Edit")}
                width={700}
                cancelText={t("Cancel")}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    ref={formRef}
                    >
                    <Form.Item
                        initialValue={selectedRows.title}
                        name="title"
                        label="Tiêu đề:"
                        rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
                    >
                        <Input placeholder="Tiêu đề" />
                    </Form.Item>

                    <Form.Item
                        initialValue={selectedRows.description}
                        name="description"
                        label="Mô tả:"
                        rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}
                    >
                        <Input.TextArea placeholder="Mô tả" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Popup;